import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import ProgramBanner from "../components/program/Banner";
import ProgramNews from "../components/program/News";
import YayasanBannerMultiple from "../components/program/BannerMultiple";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}

export default function Program() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "banner";

  const [currentTab, setCurrentTab] = useState<string>(initialTab);

  useEffect(() => {
    if (searchParams.get("tab") !== currentTab) {
      setSearchParams({ tab: currentTab });
    }
  }, [currentTab, searchParams, setSearchParams]);

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== currentTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {value === currentTab && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <div className="mb-8">
        <h1 className="font-bold text-3xl mb-2">
          Mengatur Halaman Menu Pelaksana Donasi
        </h1>
        <p className="text-sm text-neutral-600">
          Pengaturan Konten di Menu Program
        </p>
      </div>

      <Tabs
        value={currentTab}
        onChange={(e, value) => setCurrentTab(value)}
        className="w-full mb-4"
      >
        <Tab label="Banner" value={"banner"} />
        <Tab label="News" value={"news"} />
      </Tabs>

      <CustomTabPanel value={"banner"} index={0}>
        {/* <ProgramBanner /> */}
        <YayasanBannerMultiple />
      </CustomTabPanel>
      <CustomTabPanel value={"news"} index={1}>
        <ProgramNews />
      </CustomTabPanel>
    </>
  );
}
