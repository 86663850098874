import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {
  addMasterOrg,
  addMasterPosition,
  deleteMasterOrg,
  deleteMasterOrgPosition,
  editMasterOrg,
  editMasterPosition,
  getMasterOrg,
  getMasterPosition,
  getMasterOrgByIdPosition,
} from "../../api/org";
import { useLoader } from "../../context/loaderContext";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FieldWithLabel from "../FieldWithLabel";
import { getDropOrganization } from "../../api/dropdown";
import FileUploaderSingle from "../FileUploaderSingle";
import { uploadFile } from "../../api/file";

type FormOrganization = {
  name_id: string;
  name_en: string;
  level: number;
};

type FormPostion = {
  organization_id: string;
  name_id: string;
  name_en: string;
  incumbent: string;
  level: number;
};

const validationSchema = Yup.object({
  name_id: Yup.string().required("Nama Organisasi wajib diisi"),
  name_en: Yup.string().required("Master program wajib diisi"),
  level: Yup.number().required("Level wajib diisi"),
});

const validationSchemaPosition = Yup.object({
  organization_id: Yup.string().required("Organisasi wajib diisi"),
  name_id: Yup.string().required("Nama posisi wajib diisi"),
  name_en: Yup.string().required("Nama posisi wajib diisi"),
  incumbent: Yup.string().required("Nama Pejabat wajib diisi"),
  level: Yup.number().required("Level wajib diisi"),
});

const defaultValues = {
  name_id: "",
  name_en: "",
  level: 0,
};

const defaultValuesPosition = {
  organization_id: "",
  name_id: "",
  name_en: "",
  incumbent: "",
  level: 0,
};

const columns = [
  { id: "image", label: "Foto" },
  { id: "name", label: "Position" },
  { id: "incumbent", label: "Nama" },
  { id: "level_position", label: "Level Posisi" },
  { id: "level", label: "Level Organisiasi" },
  {
    id: "",
    label: "",
  },
];

export default function YayasanStructureOrg({
  openOrg,
  onCloseOrganization,
  openPosition,
  onClosePosition,
  handleOpenPositionDialog,
  formPosition,
}: any) {
  const { showLoader, hideLoader } = useLoader();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
  const [selectedPosition, setSelectedPosition] = useState<any>("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [ddOrganization, setDdProgram] = useState<any>([]);
  const [programIdToDelete, setProgramIdToDelete] = useState<number | null>(
    null
  );
  const [imagePosition, setImagePosition] = useState<File | null>(null);
  const [previewImagePosition, setPreviewImagePosition] = useState<string>("");

  const [isEditPosition, setIsEditPosition] = useState<boolean>(false);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") return; // Avoid closing on click outside
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const formMethods = useForm<FormOrganization>({
    resolver: yupResolver(validationSchema),
    defaultValues: isEditPosition ? selectedOrganization : defaultValues,
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const getMasterOrganizationHandler = async () => {
    try {
      showLoader();
      const res = await getMasterOrg();
      setData(res.data);
    } catch (error) {
      console.error("Error fetching program:", error);
    } finally {
      hideLoader();
    }
  };

  const resetForm = () => {
    formMethods.reset();
  };

  const formMethodsSub = useForm<FormPostion>({
    resolver: yupResolver(validationSchemaPosition),
    defaultValues: defaultValuesPosition,
  });

  const {
    register: registerPosition,
    setValue: setValuePosition,
    getValues: getValuePosition,
    handleSubmit: handleSubmitPosition,
    formState: { errors: errorsPosition },
    control,
  } = formMethodsSub;

  const getMasterPositionHandler = async (page = 1, limit = 5) => {
    try {
      showLoader();
      const res = await getMasterPosition();
      setDataPosition(res.data);
    } catch (error) {
      console.error("Error fetching program sub:", error);
    } finally {
      hideLoader();
    }
  };

  const resetFormPosition = () => {
    formMethodsSub.reset();
  };

  useEffect(() => {
    if (openOrg) {
      const fetchData = async () => {
        showLoader();
        try {
          getMasterOrganizationHandler();
        } catch (error) {
          console.error("Error fetching program:", error);
        } finally {
          hideLoader();
        }
      };

      fetchData();
    }
  }, [openOrg, showLoader, hideLoader]);

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const programResponse = await getDropOrganization();
        setDdProgram(programResponse.data);
        await getMasterPositionHandler();
      } catch (error) {
        console.error("Error fetching data:", error);
        setSnackbar({
          open: true,
          message: "Gagal memuat data",
          severity: "error",
        });
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);

  const setEditForm = (row: any) => {
    setValue("name_id", row.name_id);
    setValue("name_en", row.name_en);
    setValue("level", row.level);
    setSelectedOrganization(row.id);
  };

  useEffect(() => {
    if (formPosition) {
      setValuePosition("organization_id", formPosition.organization_id);
      setValuePosition("name_id", formPosition.name_id);
      setValuePosition("name_en", formPosition.name_en);
      setValuePosition("incumbent", formPosition.incumbent);
      setValuePosition("level", formPosition.level);
      setPreviewImagePosition(formPosition.image);
    }
  }, [selectedPosition, setValuePosition, openPosition, formPosition]);

  const setEditFormSub = async (row: any) => {
    try {
      const res = await getMasterOrgByIdPosition(row.id);
      if (!res?.status) {
        throw res;
      }
      console.log(res.data);
      setIsEditPosition(true);
      setSelectedOrganization(row.id);
      handleOpenPositionDialog(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitMaster = async (data: FormOrganization) => {
    showLoader();
    try {
      let formData: any = {
        ...data,
        description_id: "",
        description_en: "",
      };

      if (selectedOrganization) {
        await editMasterOrg(selectedOrganization, formData);
        setSnackbar({
          open: true,
          message: "Organisasi berhasil diperbarui",
          severity: "success",
        });
        resetForm();
        setSelectedOrganization(null);
      } else {
        await addMasterOrg(formData);
        setSnackbar({
          open: true,
          message: "Organisasi berhasil ditambahkan",
          severity: "success",
        });
        resetForm();
      }
    } catch (error) {
      console.error("Error submitting form", error);
      setSnackbar({
        open: true,
        message: "Terjadi kesalahan",
        severity: "error",
      });
    } finally {
      getMasterOrganizationHandler();
      hideLoader();
      onCloseOrganization();
    }
  };

  const onSubmitPosition = async (data: FormPostion) => {
    showLoader();
    try {
      let formDataPosition: any = {
        ...data,
      };

      if (imagePosition && imagePosition instanceof File) {
        try {
          const fD = new FormData();
          fD.append("file", imagePosition);

          const result = await uploadFile(fD);
          const path = result.data as string;
          formDataPosition.image = path;
        } catch (error) {
          console.error("Error uploading file", error);
        }
      }

      if (formPosition?.id) {
        await editMasterPosition(formPosition.id, formDataPosition);
        setSnackbar({
          open: true,
          message: "Posisi berhasil diperbarui",
          severity: "success",
        });
        resetFormPosition();
        setSelectedPosition(null);
        setIsEditPosition(false);
      } else {
        await addMasterPosition(formDataPosition);
        setSnackbar({
          open: true,
          message: "Posisi berhasil ditambahkan",
          severity: "success",
        });
        resetFormPosition();
      }
    } catch (error) {
      console.error("Error submitting form", error);
      setSnackbar({
        open: true,
        message: "Terjadi kesalahan",
        severity: "error",
      });
    } finally {
      getMasterPositionHandler();
      hideLoader();
      onClosePosition();
    }
  };

  const handleDelete = async () => {
    if (!programIdToDelete) return;

    try {
      showLoader();
      const apiCall =
        deleteType === "sub" ? deleteMasterOrgPosition : deleteMasterOrg;
      await apiCall(programIdToDelete);

      setData((prev) =>
        prev.filter((item: any) => item.id !== programIdToDelete)
      );
      setSnackbar({
        open: true,
        message: "Program berhasil dihapus",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting program:", error);
      setSnackbar({
        open: true,
        message: "Gagal menghapus program",
        severity: "error",
      });
    } finally {
      setProgramIdToDelete(null);
      setOpenConfirmDialog(false);
      hideLoader();

      if (deleteType === "sub") getMasterPositionHandler(1);
    }
  };

  const openDeleteDialog = (newsId: number, isSub?: boolean) => {
    setProgramIdToDelete(newsId);
    setOpenConfirmDialog(true);
    setDeleteType(isSub ? "sub" : "");
  };

  const handleFileChangeImage = (data: any) => {
    setImagePosition(data?.file);
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Konfirmasi Hapus</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Apakah Anda yakin ingin menghapus program?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="secondary">
            Batal
          </Button>
          <Button onClick={handleDelete} color="error">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: "100%", overflow: "hidden", padding: 2 }}>
        <Dialog
          open={openOrg}
          onClose={onCloseOrganization}
          maxWidth="md"
          fullWidth
          keepMounted
        >
          <DialogTitle className="bg-primary-100 flex justify-between items-center">
            <span className="text-base font-semibold">
              Tambah Master Organisasi
            </span>
            <div
              className="bg-white cursor-pointer border size-8 flex justify-center items-center rounded"
              onClick={onCloseOrganization}
            >
              <CloseIcon />
            </div>
          </DialogTitle>
          <DialogContent>
            <form
              onSubmit={handleSubmit(onSubmitMaster)}
              className="w-full flex flex-row gap-4 mt-4 items-end"
            >
              <FieldWithLabel label="Organisasi (Bahasa Indonesia)" required>
                <TextField
                  {...register("name_id")}
                  error={!!errors.name_id}
                  helperText={errors.name_id?.message}
                />
              </FieldWithLabel>

              <FieldWithLabel label="Organisasi (Bahasa Inggris)" required>
                <TextField
                  {...register("name_en")}
                  error={!!errors.name_en}
                  helperText={errors.name_en?.message}
                />
              </FieldWithLabel>

              <FieldWithLabel label="Level" required>
                <TextField
                  {...register("level")}
                  type="number"
                  error={!!errors.level}
                  helperText={errors.level?.message}
                />
              </FieldWithLabel>

              <div className="w-[250px] flex gap-2 !mb-[8px]">
                <Button type="submit" className="h-[40px] !px-4 flex gap-2">
                  {selectedOrganization ? (
                    <>
                      <EditIcon />
                      <Typography textTransform={"capitalize"}>Ubah</Typography>
                    </>
                  ) : (
                    <>
                      <AddIcon />
                      <Typography textTransform={"capitalize"}>
                        Tambah
                      </Typography>
                    </>
                  )}
                </Button>

                {selectedOrganization && (
                  <IconButton
                    onClick={() => {
                      resetForm();
                      setSelectedOrganization(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            </form>

            <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className="bg-red-100">
                  <TableRow>
                    <TableCell>
                      <span className="font-bold text-base">
                        Organisasi (Indonesia)
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="font-bold text-base">
                        Organisasi (Inggris)
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="font-bold text-base">Level</span>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell key="name_id">{row.name_id}</TableCell>
                        <TableCell key="name_en">{row.name_en}</TableCell>
                        <TableCell key="level">{row.level}</TableCell>
                        <TableCell key="action">
                          <div className="w-[40px] flex gap-2">
                            <IconButton onClick={() => setEditForm(row)}>
                              <EditIcon />
                            </IconButton>

                            <IconButton
                              onClick={() => openDeleteDialog(row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openPosition}
          onClose={onClosePosition}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle className="bg-primary-100 flex justify-between items-center">
            <span className="text-base font-semibold">
              {formPosition?.id ? "Ubah" : "Tambah"} Posisi
            </span>
            <div
              className="bg-white cursor-pointer border size-8 flex justify-center items-center rounded"
              onClick={onClosePosition}
            >
              <CloseIcon />
            </div>
          </DialogTitle>
          <DialogContent>
            <form
              onSubmit={handleSubmitPosition(onSubmitPosition)}
              className="w-full flex flex-col gap-4 mt-4 items-end"
            >
              <div className="grid grid-cols-2 w-full gap-4">
                <FieldWithLabel
                  label="Pilih Organisasi"
                  required
                  applyMargin={true}
                >
                  <Controller
                    name="organization_id"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        error={!!errorsPosition.organization_id}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                      >
                        {ddOrganization.map((program: any, idx: number) => (
                          <MenuItem key={idx} value={program.key}>
                            {program.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errorsPosition.organization_id && (
                    <Typography variant="caption" color="error">
                      {errorsPosition.organization_id.message}
                    </Typography>
                  )}
                </FieldWithLabel>

                <FieldWithLabel label="Level" required>
                  <TextField
                    {...registerPosition("level")}
                    type="number"
                    error={!!errors.level}
                    helperText={errors.level?.message}
                  />
                </FieldWithLabel>

                <FieldWithLabel label="Nama Posisi (Bahasa Indonesia)" required>
                  <TextField
                    {...registerPosition("name_id")}
                    error={!!errorsPosition.name_id}
                    helperText={errorsPosition.name_id?.message}
                  />
                </FieldWithLabel>

                <FieldWithLabel label="Nama Posisi (Bahasa Inggris)" required>
                  <TextField
                    {...registerPosition("name_en")}
                    error={!!errorsPosition.name_en}
                    helperText={errorsPosition.name_en?.message}
                  />
                </FieldWithLabel>
              </div>

              <FieldWithLabel label="Nama Pejabat" required>
                <TextField
                  {...registerPosition("incumbent")}
                  error={!!errorsPosition.incumbent}
                  helperText={errorsPosition.incumbent?.message}
                />
              </FieldWithLabel>

              <FieldWithLabel label="Foto Pejabat" required applyMargin>
                <FileUploaderSingle
                  onChange={handleFileChangeImage}
                  initialFile={previewImagePosition}
                />
              </FieldWithLabel>

              <div className="w-[150px] flex gap-2 !mb-[8px]">
                <Button type="submit" className="h-[40px] !px-4 flex gap-2">
                  <CheckIcon />
                  <Typography textTransform={"capitalize"}>Simpan</Typography>
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        <TableContainer sx={{ marginTop: 2 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((col) => {
                  return <TableCell key={col.id}>{col.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataPosition && dataPosition.map((row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="image">
                      <img
                        src={row.image}
                        alt={`${row.id}-${row.incumbent}`}
                        className="rounded-full size-10 object-cover"
                      />
                    </TableCell>
                    <TableCell key="name">{row.name}</TableCell>
                    <TableCell key="incumbent">{row.incumbent}</TableCell>
                    <TableCell key="level_position">{row.level || 1}</TableCell>
                    <TableCell key="level">{row.organization.level}</TableCell>
                    <TableCell key="action">
                      <div className="w-[85px]">
                        <IconButton onClick={() => setEditFormSub(row)}>
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => openDeleteDialog(row.id, true)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={dataPosition.length}
          rowsPerPage={rowsPerPage}
          page={page}
        /> */}
      </Paper>
    </>
  );
}
