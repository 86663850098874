import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {
  addMasterProgram,
  addMasterProgramSub,
  deleteMasterProgram,
  deleteMasterProgramSub,
  editMasterProgram,
  editMasterProgramSub,
  getMasterProgram,
  getMasterProgramByIdSub,
  getMasterProgramSub,
} from "../../api/master";
import { useLoader } from "../../context/loaderContext";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FieldWithLabel from "../FieldWithLabel";
import { getDropProgram } from "../../api/dropdown";

type FormData = {
  name_id: string;
  name_en: string;
  priority: number;
};

type FormDataSub = {
  program: string;
  title_id: string;
  description_id: string;
  title_en: string;
  description_en: string;
};

const validationSchema = Yup.object({
  name_id: Yup.string().required("Master program wajib diisi"),
  name_en: Yup.string().required("Master program wajib diisi"),
  priority: Yup.number().required("Prioritas wajib diisi"),
});

const validationSchemaSub = Yup.object({
  program: Yup.string().required("Program wajib diisi"),
  title_id: Yup.string().required("Sub program wajib diisi"),
  description_id: Yup.string().defined(),
  title_en: Yup.string().required("Sub program wajib diisi"),
  description_en: Yup.string().defined(),
});

const defaultValues = {
  name_id: "",
  name_en: "",
  priority: 0,
};

const defaultValuesSub = {
  program: "",
  title_id: "",
  description_id: "",
  title_en: "",
  description_en: "",
};

const columns = [
  { id: "name", label: "Nama Program" },
  { id: "sub", label: "Sub Program" },
  { id: "description", label: "Deskripsi" },
  {
    id: "",
    label: "",
  },
];

export default function YayasanProgram({
  openProgram,
  onCloseProgram,
  openProgramSub,
  onCloseProgramSub,
  handleOpenProgramSubDialog,
  formSub,
}: any) {
  const { showLoader, hideLoader } = useLoader();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [dataSub, setDataSub] = useState([]);
  const [dataSubTotal, setDataSubTotal] = useState(0);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedProgramSub, setSelectedProgramSub] = useState<any>("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [ddProgram, setDdProgram] = useState<any>([]);
  const [programIdToDelete, setProgramIdToDelete] = useState<number | null>(
    null
  );

  const [isEditSub, setIsEditSub] = useState<boolean>(false)

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") return; // Avoid closing on click outside
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const formMethods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: isEditSub ? selectedProgramSub : defaultValues,
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const getMasterProgramHandler = async () => {
    try {
      showLoader();
      const res = await getMasterProgram();
      setData(res.data.data);
      setDataTotal(res.data.total);
    } catch (error) {
      console.error("Error fetching program:", error);
    } finally {
      hideLoader();
    }
  };

  const resetForm = () => {
    formMethods.reset();
  };

  const formMethodsSub = useForm<FormDataSub>({
    resolver: yupResolver(validationSchemaSub),
    defaultValues: defaultValuesSub,
  });

  const {
    register: registerSub,
    setValue: setValueSub,
    getValues: getValueSub,
    handleSubmit: handleSubmitSub,
    formState: { errors: errorsSub },
    control,
  } = formMethodsSub;

  const getMasterProgramSubHandler = async (page = 1, limit = 5) => {
    try {
      showLoader();
      const res = await getMasterProgramSub({ page, limit });
      setDataSub(res.data.data);
      setDataSubTotal(res.data.total);
    } catch (error) {
      console.error("Error fetching program sub:", error);
    } finally {
      hideLoader();
    }
  };

  const resetFormSub = () => {
    formMethodsSub.reset();
  };

  useEffect(() => {
    if (openProgram) {
      const fetchData = async () => {
        showLoader();
        try {
          getMasterProgramHandler();
        } catch (error) {
          console.error("Error fetching program:", error);
        } finally {
          hideLoader();
        }
      };

      fetchData();
    }
  }, [openProgram, showLoader, hideLoader]);

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const programResponse = await getDropProgram();
        setDdProgram(programResponse.data);
        await getMasterProgramSubHandler();
      } catch (error) {
        console.error("Error fetching data:", error);
        setSnackbar({
          open: true,
          message: "Gagal memuat data",
          severity: "error",
        });
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);

  const setEditForm = (row: any) => {
    setValue("name_id", row.name_id);
    setValue("name_en", row.name_en);
    setValue("priority", row.priority);
    setSelectedProgram(row.id);
  };

  useEffect(() => {
    if (formSub) {
      setValueSub("program", formSub.program);
      setValueSub("title_id", formSub.title_id);
      setValueSub("title_en", formSub.title_en);
      setValueSub("description_id", formSub.description_id);
      setValueSub("description_en", formSub.description_en);
    }
  }, [selectedProgramSub, setValueSub, openProgramSub, formSub]);

  const setEditFormSub = async (row: any) => {
    try {
      const res = await getMasterProgramByIdSub(row.id)
      if (!res?.status) {
        throw res
      }
      console.log(res.data)
      setIsEditSub(true)
      setSelectedProgramSub(row.id);
      handleOpenProgramSubDialog(res.data);
    } catch (error) {
      console.log(error)
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage);
    await getMasterProgramSubHandler(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await getMasterProgramSubHandler(1, newRowsPerPage);
  };

  const onSubmitMaster = async (data: FormData) => {
    showLoader();
    try {
      let formData: any = {
        ...data,
        description_id: "",
        description_en: "",
      };

      if (selectedProgram) {
        await editMasterProgram(selectedProgram, formData);
        setSnackbar({
          open: true,
          message: "Master program berhasil diperbarui",
          severity: "success",
        });
        resetForm();
        setSelectedProgram(null);
      } else {
        await addMasterProgram(formData);
        setSnackbar({
          open: true,
          message: "Master program berhasil ditambahkan",
          severity: "success",
        });
        resetForm();
      }
    } catch (error) {
      console.error("Error submitting form", error);
      setSnackbar({
        open: true,
        message: "Terjadi kesalahan",
        severity: "error",
      });
    } finally {
      getMasterProgramHandler();
      hideLoader();
      onCloseProgram();
    }
  };

  const onSubmitMasterSub = async (data: FormDataSub) => {
    showLoader();
    try {
      let formDataSub: any = {
        ...data,
      };

      if (formSub?.id) {
        await editMasterProgramSub(formSub.id, formDataSub);
        setSnackbar({
          open: true,
          message: "Program berhasil diperbarui",
          severity: "success",
        });
        resetFormSub();
        setSelectedProgramSub(null);
        setIsEditSub(false)
      } else {
        await addMasterProgramSub(formDataSub);
        setSnackbar({
          open: true,
          message: "Program berhasil ditambahkan",
          severity: "success",
        });
        resetFormSub();
      }
    } catch (error) {
      console.error("Error submitting form", error);
      setSnackbar({
        open: true,
        message: "Terjadi kesalahan",
        severity: "error",
      });
    } finally {
      getMasterProgramSubHandler();
      hideLoader();
      onCloseProgramSub();
    }
  };

  const handleDelete = async () => {
    if (!programIdToDelete) return;

    try {
      showLoader();
      const apiCall =
        deleteType === "sub" ? deleteMasterProgramSub : deleteMasterProgram;
      await apiCall(programIdToDelete);

      setData((prev) =>
        prev.filter((item: any) => item.id !== programIdToDelete)
      );
      setSnackbar({
        open: true,
        message: "Program berhasil dihapus",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting program:", error);
      setSnackbar({
        open: true,
        message: "Gagal menghapus program",
        severity: "error",
      });
    } finally {
      setProgramIdToDelete(null);
      setOpenConfirmDialog(false);
      hideLoader();

      if (deleteType === "sub") getMasterProgramSubHandler(1);
    }
  };

  const openDeleteDialog = (newsId: number, isSub?: boolean) => {
    setProgramIdToDelete(newsId);
    setOpenConfirmDialog(true);
    setDeleteType(isSub ? "sub" : "");
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Konfirmasi Hapus</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Apakah Anda yakin ingin menghapus program?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="secondary">
            Batal
          </Button>
          <Button onClick={handleDelete} color="error">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: "100%", overflow: "hidden", padding: 2 }}>
        <Dialog
          open={openProgram}
          onClose={onCloseProgram}
          maxWidth="md"
          fullWidth
          keepMounted
        >
          <DialogTitle className="bg-primary-100 flex justify-between items-center">
            <span className="text-base font-semibold">
              Tambah Master Program
            </span>
            <div
              className="bg-white cursor-pointer border size-8 flex justify-center items-center rounded"
              onClick={onCloseProgram}
            >
              <CloseIcon />
            </div>
          </DialogTitle>
          <DialogContent>
            <form
              onSubmit={handleSubmit(onSubmitMaster)}
              className="w-full flex flex-row gap-4 mt-4 items-end"
            >
              <FieldWithLabel
                label="Master Program (Bahasa Indonesia)"
                required
              >
                <TextField
                  {...register("name_id")}
                  error={!!errors.name_id}
                  helperText={errors.name_id?.message}
                />
              </FieldWithLabel>

              <FieldWithLabel label="Master Program (Bahasa Inggris)" required>
                <TextField
                  {...register("name_en")}
                  error={!!errors.name_en}
                  helperText={errors.name_en?.message}
                />
              </FieldWithLabel>

              <FieldWithLabel label="Prioritas" required>
                <TextField
                  {...register("priority")}
                  type="number"
                  error={!!errors.priority}
                  helperText={errors.priority?.message}
                />
              </FieldWithLabel>

              <div className="w-[250px] flex gap-2 !mb-[8px]">
                <Button type="submit" className="h-[40px] !px-4 flex gap-2">
                  {selectedProgram ? (
                    <>
                      <EditIcon />
                      <Typography textTransform={"capitalize"}>Ubah</Typography>
                    </>
                  ) : (
                    <>
                      <AddIcon />
                      <Typography textTransform={"capitalize"}>
                        Tambah
                      </Typography>
                    </>
                  )}
                </Button>

                {selectedProgram && (
                  <IconButton
                    onClick={() => {
                      resetForm();
                      setSelectedProgram(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            </form>

            <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className="bg-red-100">
                  <TableRow>
                    <TableCell>
                      <span className="font-bold text-base">
                        Program (Indonesia)
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="font-bold text-base">
                        Program (Inggris)
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="font-bold text-base">Prioritas</span>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell key="name_id">{row.name_id}</TableCell>
                        <TableCell key="name_en">{row.name_en}</TableCell>
                        <TableCell key="priority">{row.priority}</TableCell>
                        <TableCell key="action">
                          <div className="w-[40px] flex gap-2">
                            <IconButton onClick={() => setEditForm(row)}>
                              <EditIcon />
                            </IconButton>

                            <IconButton
                              onClick={() => openDeleteDialog(row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openProgramSub}
          onClose={onCloseProgramSub}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle className="bg-primary-100 flex justify-between items-center">
            <span className="text-base font-semibold">
              {formSub?.id ? "Ubah" : "Tambah"} Sub Program
            </span>
            <div
              className="bg-white cursor-pointer border size-8 flex justify-center items-center rounded"
              onClick={onCloseProgramSub}
            >
              <CloseIcon />
            </div>
          </DialogTitle>
          <DialogContent>
            <form
              onSubmit={handleSubmitSub(onSubmitMasterSub)}
              className="w-full flex flex-col gap-4 mt-4 items-end"
            >
              <FieldWithLabel
                label="Pilih Kategori"
                required
                applyMargin={true}
              >
                <Controller
                  name="program"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      error={!!errorsSub.program}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                    >
                      {ddProgram.map((program: any, idx: number) => (
                        <MenuItem key={idx} value={program.key}>
                          {program.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errorsSub.program && (
                  <Typography variant="caption" color="error">
                    {errorsSub.program.message}
                  </Typography>
                )}
              </FieldWithLabel>

              <div className="grid grid-cols-2 w-full gap-4">
                <FieldWithLabel label="Sub Program (Bahasa Indonesia)" required>
                  <TextField
                    {...registerSub("title_id")}
                    error={!!errorsSub.title_id}
                    helperText={errorsSub.title_id?.message}
                  />
                </FieldWithLabel>

                <FieldWithLabel label="Sub Program (Bahasa Inggris)" required>
                  <TextField
                    {...registerSub("title_en")}
                    error={!!errorsSub.title_en}
                    helperText={errorsSub.title_en?.message}
                  />
                </FieldWithLabel>
              </div>

              <FieldWithLabel label="Deskripsi (Bahasa Indonesia)" required>
                <TextField
                  {...registerSub("description_id")}
                  error={!!errorsSub.description_id}
                  helperText={errorsSub.description_id?.message}
                />
              </FieldWithLabel>

              <FieldWithLabel label="Sub Program (Bahasa Inggris)" required>
                <TextField
                  {...registerSub("description_en")}
                  error={!!errorsSub.description_en}
                  helperText={errorsSub.description_en?.message}
                />
              </FieldWithLabel>

              <div className="w-[150px] flex gap-2 !mb-[8px]">
                <Button type="submit" className="h-[40px] !px-4 flex gap-2">
                  <CheckIcon />
                  <Typography textTransform={"capitalize"}>Simpan</Typography>
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        <TableContainer sx={{ marginTop: 2 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((col) => {
                  return <TableCell key={col.id}>{col.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSub.map((row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="program">{row.program}</TableCell>
                    <TableCell key="title">{row.title}</TableCell>
                    <TableCell key="description">{row.description}</TableCell>
                    <TableCell key="action">
                      <div className="w-[85px]">
                        <IconButton onClick={() => setEditFormSub(row)}>
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => openDeleteDialog(row.id, true)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={dataSubTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
