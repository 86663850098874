import { useState, useEffect } from "react";

import dayjs from "dayjs";
import "dayjs/locale/id";

import { Box, Divider, Button } from "@mui/material";

dayjs.locale("id");

type FileType = File | string | null | undefined;

export default function NewsPreview({ dataNews }: any) {
  // const router = useNavigate();

  const [news, setNews] = useState<any>(null);
  const [readMore, setReadMore] = useState<boolean>(false);
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    setNews(dataNews);
  }, [dataNews]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert("Link copied to clipboard!");
    });
  };

  if (!news) {
    return <div className="min-h-screen">Loading...</div>;
  }

  const isVideo = (url: string): boolean =>
    /\.(mp4|mov|avi|mkv|webm)$/i.test(url);

  const renderFile = (file: FileType) => {
    if (!file) return null; // No file to display

    const mediaSrc = file instanceof File ? URL.createObjectURL(file) : file;

    const isImageFile =
      (file instanceof File && file.type.startsWith("image/")) ||
      (typeof file === "string" && !isVideo(file));

    const isVideoFile =
      (file instanceof File && file.type.startsWith("video/")) ||
      (typeof file === "string" && isVideo(file));

    if (isImageFile) {
      return (
        <img
          src={mediaSrc}
          alt="Uploaded Media"
          className="w-full object-cover h-full rounded-md"
        />
      );
    }

    if (isVideoFile) {
      return (
        <video
          src={mediaSrc}
          className="w-full object-cover h-full rounded-md"
          controls
        />
      );
    }

    return null;
  };

  return (
    <Box>
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row gap-4 pb-8">
          {/* Main Content */}
          <div className="w-full">
            <div className="bg-white rounded-xl">
              <h1 className="text-2xl text-justify md:text-start md:text-4xl font-bold mb-4">
                {news.title_id}
              </h1>

              <img
                src={news.image || "/default-thumbnail.png"}
                alt={news.title_id || "No Title"}
                width={1024}
                height={600}
                className="md:object-cover w-full object-top rounded-xl md:h-[600px] mb-4"
              />

              <div className="mt-6 mb-2">
                {"Oleh"}{" "}
                <span className="text-primary-600">
                  {news.author || "Admin"}
                </span>
                ,{" "}
                {news.date
                  ? dayjs(news.date).locale("id").format("dddd, DD MMMM YYYY")
                  : "Unknown date"}
              </div>
              <div className="text-justify mb-4">
                {news.content_id ? (
                  news.content_id.length > 500 && !readMore ? (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${news.content_id.substring(0, 500)} ...`,
                        }}
                      ></div>
                      <div className="flex justify-center">
                        <Button
                          className="rounded-md"
                          variant="text"
                          onClick={() => {
                            setReadMore(!readMore);
                          }}
                        >
                          {"Selengkapnya"}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: news.content_id }}
                    ></div>
                  )
                ) : (
                  "No content available."
                )}
              </div>

              <Divider className="my-4" />
            </div>

            <div className="max-h-full">
              {renderFile(dataNews.testimoni_banner)}
            </div>

            <div className="bg-white mt-4">
              <div className="p-4 border text-center font-bold rounded-tl-sm text-3xl rounded-tr-sm border-slate-200">
                {`${"Kata Mereka"}`}
              </div>
              <div className="flex justify-center py-2 gap-4">
                {news?.testimonies.map((d: any, index: number) => (
                  <div
                    key={index}
                    className="border shadow-sm rounded-md md:w-1/2 w-[90%] bg-white p-4 text-sm"
                  >
                    <div className="font-bold text-center">{d.name}</div>
                    <div className="text-neutral-400 text-center font-semibold mb-4">
                      {dayjs(d.created_at).locale("id").format("DD MMMM YYYY")}
                    </div>
                    <div className="rounded-sm text-center">{d.testimoni}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-white">
              <div className="p-4 border font-bold rounded-tl-sm rounded-tr-sm border-slate-200">
                {"Galeri"}
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 p-4 gap-4 border rounded-bl-sm rounded-br-sm border-slate-200">
                {news.galeries.map((d: any, index: number) => {
                  return (
                    <div key={index} className="border shadow-sm rounded-md">
                      {renderFile(d.file)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
