import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

interface UploadedFile {
  file: File;
  image: string;
  caption: string;
}

interface InitialFile {
  image: string;
  caption: string;
}

interface FileUploaderProps {
  onChange: (files: UploadedFile[] | InitialFile[], type: string) => void;
  initialFiles: InitialFile[];
  caption?: boolean;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileUploader: React.FC<FileUploaderProps> = ({
  onChange,
  initialFiles,
  caption = true,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [previewFiles, setPreviewFiles] = useState<InitialFile[]>([]);

  useEffect(() => {
    setPreviewFiles(initialFiles);
  }, [initialFiles]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files).filter((file) =>
        ["image/", "video/"].some((type) => file.type.startsWith(type))
      );

      const newFiles = filesArray.map((file) => ({
        file,
        image: URL.createObjectURL(file),
        caption: "",
      }));

      const updatedFiles = [...uploadedFiles, ...newFiles];
      setUploadedFiles(updatedFiles);
      onChange(updatedFiles, "upload");
    }
  };

  const handleCaptionChange = (
    index: number,
    caption: string,
    isPreview: boolean
  ) => {
    let updatedFilesData: any = [];

    if (isPreview) {
      const updatedPreviewFiles = previewFiles.map((file, i) =>
        i === index ? { ...file, caption } : file
      );
      setPreviewFiles(updatedPreviewFiles);
      updatedFilesData = [...updatedFilesData, ...updatedPreviewFiles];
      onChange(updatedFilesData, "preview");
    } else {
      const updatedFiles = uploadedFiles.map((file, i) =>
        i === index ? { ...file, caption } : file
      );
      setUploadedFiles(updatedFiles);
      updatedFilesData = [...updatedFilesData, ...updatedFiles];
      onChange(updatedFilesData, "upload");
    }
  };

  const handleRemoveFile = (index: number, isPreview: boolean) => {
    let updatedFilesData: any = [];

    if (isPreview) {
      const updatedPreviewFiles = previewFiles.filter((_, i) => i !== index);
      setPreviewFiles(updatedPreviewFiles);
      updatedFilesData = [...updatedFilesData, ...updatedPreviewFiles];
      onChange(updatedFilesData, "preview");
    } else {
      const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
      setUploadedFiles(updatedFiles);
      updatedFilesData = [...updatedFilesData, ...updatedFiles];
      onChange(updatedFilesData, "upload");
    }
  };

  return (
    <div>
      <div className="w-fit">
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Unggah file
          <VisuallyHiddenInput
            type="file"
            multiple
            accept="image/*,video/*"
            onChange={handleFileChange}
          />
        </Button>
      </div>

      <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
        {previewFiles.map((file, index) => (
          <div
            key={`preview-${index}`}
            className="border rounded-lg p-2 flex flex-col items-center justify-center"
          >
            {(file.image && file.image.endsWith(".mp4")) ||
            file.image.endsWith(".avi") ||
            file.image.endsWith(".mov") ||
            file.image.endsWith(".webm") ? (
              <video
                src={file.image}
                className="h-56 object-cover mx-auto rounded"
                controls
              />
            ) : (
              <img
                src={file.image}
                alt={`Preview ${index}`}
                className="h-56 object-cover mx-auto rounded"
              />
            )}

            <div className="flex items-center mt-2 gap-2 w-full">
              {caption && (
                <TextField
                  placeholder="Caption"
                  value={file.caption}
                  className="!mt-2"
                  onChange={(e) =>
                    handleCaptionChange(index, e.target.value, true)
                  }
                />
              )}
              
              {!caption ? (
                <Button
                  color="primary"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleRemoveFile(index, true)}
                ></Button>
              ) : (
                <IconButton
                  color="primary"
                  onClick={() => handleRemoveFile(index, true)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </div>
        ))}

        {uploadedFiles.map((uploadedFile, index) => (
          <div
            key={`uploaded-${index}`}
            className="border rounded-lg p-2 flex flex-col items-center justify-center"
          >
            {uploadedFile.file.type.startsWith("image/") ? (
              <img
                src={uploadedFile.image}
                alt={`Uploaded Preview ${index}`}
                className="h-52 object-cover mx-auto rounded-md"
              />
            ) : (
              <video
                src={uploadedFile.image}
                className="h-52 rounded-md mx-auto"
                controls
              />
            )}
            <div className="flex items-center mt-2 px-4 gap-2 w-full">
              {caption && (
                <TextField
                  placeholder="Judul"
                  value={uploadedFile.caption}
                  className="!mt-2"
                  onChange={(e) =>
                    handleCaptionChange(index, e.target.value, false)
                  }
                />
              )}
              <IconButton
                color="primary"
                onClick={() => handleRemoveFile(index, false)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUploader;
