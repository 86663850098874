import React, { useState, useRef, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import IconImage from "../assets/icon/ic-image.svg";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { cn } from "../lib/utils";

interface CropperComponentProps {
  onCrop: (croppedImage: string) => void;
  defaultImage?: string | null;
  size?: "big" | "small";
  resolution?: "banner" | "mobile" | "logo" | "free";
  className?: any;
}

const ImageCropper: React.FC<CropperComponentProps> = ({
  onCrop,
  defaultImage,
  size = "big",
  resolution = "logo",
  className,
}) => {
  const [image, setImage] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const cropperRef = useRef<any>(null);

  useEffect(() => {
    if (defaultImage) {
      setImage(defaultImage);
    }
  }, [defaultImage]);

  const handleImageUpload = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result as string);
      setIsEditing(true); // Activate editing mode when a new image is uploaded
    };
    reader.readAsDataURL(file);
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleImageUpload(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      handleImageUpload(file);
    }
  };

  const handleCrop = () => {
    if (cropperRef.current?.cropper) {
      const croppedDataUrl = cropperRef.current.cropper
        .getCroppedCanvas({
          maxWidth: 1200,
          maxHeight: 800,
        })
        .toDataURL();
      onCrop(croppedDataUrl);
    }
  };

  const handleClear = () => {
    setImage(null); // Clear the current image
    setIsEditing(false); // Exit editing mode
  };

  const aspect: any = {
    banner: [18, 9],
    logo: [3, 2],
    mobile: [9, 16],
  };

  return (
    <div
      className={cn(
        `rounded-md relative ${
          size === "big" ? "w-[600px]" : "w-[300px]"
        } h-auto`,
        className
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {image && !isEditing ? (
        <div className="relative">
          <img
            src={image}
            alt="Preview"
            className="border !rounded-md bg-gray-100"
          />
          <div
            onClick={() => {
              setIsEditing(true);
            }}
            className="absolute cursor-pointer top-2 right-2 p-2 !rounded"
          >
            <EditIcon />
          </div>
        </div>
      ) : image && isEditing ? (
        <div className="relative bg-gray-500">
          <Cropper
            src={image}
            style={
              size === "big"
                ? { height: 400, maxWidth: 600 }
                : { height: 200, maxWidth: 300 }
            }
            guides={false}
            ref={cropperRef}
            viewMode={1}
            aspectRatio={
              resolution === "free"
                ? undefined
                : aspect[resolution][0] / aspect[resolution][1]
            }
            autoCrop={true}
            autoCropArea={1}
            crop={handleCrop}
          />

          <div
            onClick={handleClear}
            className="absolute cursor-pointer top-2 right-2 bg-primary-600 p-2 !rounded"
          >
            <DeleteIcon className="text-white" />
          </div>
        </div>
      ) : (
        <label
          htmlFor="imageUpload"
          className={`flex flex-col items-center justify-center w-full ${
            size === "big" ? "h-[400px]" : "h-[200px]"
          } ${
            isDragActive ? "bg-blue-100" : "bg-neutral-100"
          } border-neutral-200 cursor-pointer text-neutral-600 text-sm`}
        >
          <img src={IconImage} className="mb-4" alt="img-upload-plus" />
          <div className="text-center">
            {isDragActive ? (
              "Geser file ke sini"
            ) : (
              <span>
                Klik untuk unggah file
                <br /> atau geser ke sini
              </span>
            )}
          </div>
          <input
            type="file"
            id="imageUpload"
            className="hidden inset-0"
            accept="image/*"
            onChange={handleFileInput}
          />
        </label>
      )}
    </div>
  );
};

export default ImageCropper;
