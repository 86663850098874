import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F23110",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFE2DE",
      contrastText: "#F66D56",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        color: "primary",
        margin: "normal",
        size: "small",
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        color: "primary",
        margin: "normal",
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        variant: "outlined",
        color: "primary",
        margin: "normal",
        size: "small",
        fullWidth: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "primary",
        disableElevation: true,
        fullWidth: true,
        slot : {
          root : "span"
        }
      },
      
    },
    MuiTextarea: {
      defaultProps: {
        variant: "outlined",
        color: "primary",
        margin: "normal",
        size: "small",
        fullWidth: true
      }
    },
    MuiListItemButton: {
      root: {
        "&$selected": {
          color: "#FFFFFF",
          backgroundColor: "#F66D56",
        },
      },
    },
  },
});

export default theme;
