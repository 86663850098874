import React from 'react';

const Home: React.FC = () => {
  return (
    <div>
      <h1>Welcome to the Admin Dashboard</h1>
      <p>This is a protected page that requires authentication.</p>
    </div>
  );
};

export default Home;
