import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Tab, Tabs } from "@mui/material";
import YayasanBanner from "../components/yayasan/Banner";
import YayasanVision from "../components/yayasan/Vision";
import YayasanProgram from "../components/yayasan/Program";

import AddIcon from "@mui/icons-material/Add";
import YayasanStructureOrg from "../components/yayasan/StructureOrg";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}

export default function YayasanGSN() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "banner";
  const [currentTab, setCurrentTab] = useState<string>(initialTab);
  const [selectedSub, setSelectedSub] = useState<any>(null);
  const [openProgramDialog, setOpenProgramDialog] = useState<boolean>(false);
  const [openProgramSubDialog, setOpenProgramSubDialog] =
  useState<boolean>(false);
  
  const [selectedPosition, setSelectedPosition] = useState<any>(null);
  const [openOrganizationDialog, setOpenOrganizationDialog] =
    useState<boolean>(false);
  const [openPositionDialog, setOpenPositionDialog] = useState<boolean>(false);

  useEffect(() => {
    if (searchParams.get("tab") !== currentTab) {
      setSearchParams({ tab: currentTab });
    }
  }, [currentTab, searchParams, setSearchParams]);

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== currentTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {value === currentTab && <Box>{children}</Box>}
      </div>
    );
  }

  const handleOpenProgramDialog = () => {
    setOpenProgramDialog(true);
  };

  const handleCloseProgramDialog = () => {
    setOpenProgramDialog(false);
  };

  const handleOpenProgramSubDialog = (form?: any) => {
    if (form) {
      setSelectedSub(form);
    }
    setOpenProgramSubDialog(true);
  };

  const handleCloseProgramSubDialog = () => {
    if (selectedSub) {
      setSelectedSub(null);
    }
    setOpenProgramSubDialog(false);
  };

  // Organization
  const handleOpenOrganizationDialog = () => {
    setOpenOrganizationDialog(true);
  };

  const handleCloseOrganizationDialog = () => {
    setOpenOrganizationDialog(false);
  };

  const handleOpenPositionDialog = (form?: any) => {
    if (form) {
      setSelectedPosition(form);
    }
    setOpenPositionDialog(true);
  };

  const handleClosePositionDialog = () => {
    if (selectedSub) {
      setSelectedPosition(null);
    }
    setOpenPositionDialog(false);
  };

  return (
    <>
      <div className="mb-8 flex justify-between">
        <div>
          <h1 className="font-bold text-3xl mb-2">
            Mengatur Halaman Menu Yayasan GSN
          </h1>
          <p className="text-sm text-neutral-600">
            Pengaturan Konten di menu Yayasan GSN
          </p>
        </div>

        {currentTab === "organizational_structure" && (
          <div className="w-fit flex gap-2">
            <div className="w-fit">
              <Button variant="outlined" onClick={handleOpenPositionDialog}>
                <AddIcon className="mr-2" />
                Master Posisi
              </Button>
            </div>
            <div className="w-fit">
              <Button onClick={handleOpenOrganizationDialog}>
                <AddIcon className="mr-2" />
                Master Organisasi
              </Button>
            </div>
          </div>
        )}

        {currentTab === "program_category" && (
          <div className="w-fit flex gap-2">
            <div className="w-fit">
              <Button variant="outlined" onClick={handleOpenProgramSubDialog}>
                <AddIcon className="mr-2" />
                Sub Program
              </Button>
            </div>
            <div className="w-fit">
              <Button onClick={handleOpenProgramDialog}>
                <AddIcon className="mr-2" />
                Master Program
              </Button>
            </div>
          </div>
        )}
      </div>

      <Tabs
        value={currentTab}
        onChange={(e, value) => setCurrentTab(value)}
        className="w-full mb-4"
      >
        <Tab label="Banner" value={"banner"} />
        <Tab label="Sejarah, Visi & Misi" value={"history_vision_mission"} />
        <Tab label="Struktur Organisasi" value={"organizational_structure"} />
        <Tab label="Kategori Program" value={"program_category"} />
      </Tabs>

      <CustomTabPanel value={"banner"} index={0}>
        <YayasanBanner />
      </CustomTabPanel>
      <CustomTabPanel value={"history_vision_mission"} index={1}>
        <YayasanVision />
      </CustomTabPanel>
      <CustomTabPanel value={"organizational_structure"} index={2}>
        <YayasanStructureOrg
          formPosition={selectedPosition}
          openOrg={openOrganizationDialog}
          onCloseOrganization={handleCloseOrganizationDialog}
          openPosition={openPositionDialog}
          onClosePosition={handleClosePositionDialog}
          handleOpenPositionDialog={handleOpenPositionDialog}
        />
      </CustomTabPanel>
      <CustomTabPanel value={"program_category"} index={3}>
        <YayasanProgram
          formSub={selectedSub}
          openProgram={openProgramDialog}
          onCloseProgram={handleCloseProgramDialog}
          openProgramSub={openProgramSubDialog}
          onCloseProgramSub={handleCloseProgramSubDialog}
          handleOpenProgramSubDialog={handleOpenProgramSubDialog}
        />
      </CustomTabPanel>
    </>
  );
}
