import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  MenuItem,
  Select,
  TablePagination,
  SelectChangeEvent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getNews, deleteNews } from "../../api/news";
import FieldWithLabel from "../../components/FieldWithLabel";
import { getDropProgram } from "../../api/dropdown";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../context/loaderContext";

export default function News() {
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();

  const [newsData, setNewsData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [program, setProgram] = useState("");
  const [ddProgram, setDdProgram] = useState<any[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [newsIdToDelete, setNewsIdToDelete] = useState<number | null>(null);

  useEffect(() => {
    getDropProgram().then((res) => {
      setDdProgram(res.data);
    });
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      showLoader();
      try {
        const res = await getNews({
          page: currentPage,
          limit: rowsPerPage,
          search,
          program,
        });

        if (res.status === 1) {
          setNewsData(res.data.data);
          setTotalPages(res.data.last_page);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        hideLoader();
      }
    };

    fetchNews();
  }, [currentPage, rowsPerPage, search, program, showLoader, hideLoader]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    value: number
  ) => {
    setCurrentPage(value + 1);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleProgramChange = (event: SelectChangeEvent<string>) => {
    setProgram(event.target.value);
    setCurrentPage(1);
  };

  const handleDelete = async () => {
    if (newsIdToDelete !== null) {
      showLoader();
      const res = await deleteNews(newsIdToDelete);
      if (res.status === 1) {
        setNewsData((prev) =>
          prev.filter((item) => item.id !== newsIdToDelete)
        );
        setOpenConfirmDialog(false);
        setNewsIdToDelete(null);
      }
      hideLoader();
    }
  };

  const openDeleteDialog = (newsId: number) => {
    setNewsIdToDelete(newsId);
    setOpenConfirmDialog(true);
  };

  const handleAddNews = () => {
    navigate("/news/add");
  };

  const handleViewNews = (newsId: number) => {
    navigate(`/news/${newsId}`);
  };

  return (
    <div>
      <div className="mb-4 flex justify-between items-end gap-4">
        <div className="w-[300px]">
          <FieldWithLabel label="Program" applyMargin={true}>
            <Select onChange={handleProgramChange}>
              {ddProgram.map((program: any, idx: number) => (
                <MenuItem key={idx} value={program.key}>
                  {program.label}
                </MenuItem>
              ))}
            </Select>
          </FieldWithLabel>
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={handleAddNews}
          className="!w-fit h-9"
        >
          Tambah News
        </Button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
        {newsData.map((item) => (
          <Card key={item.id} variant="outlined">
            {item.image ? (
              <CardMedia
                component="img"
                height="140"
                image={item.image}
                alt={item.title}
              />
            ) : (
              <Skeleton variant="rectangular" height={240} />
            )}

            <CardContent
              className="cursor-pointer min-h-[95px]"
              onClick={() => handleViewNews(item.id)}
            >
              <Typography
                variant="subtitle1"
                component="div"
                className="line-clamp-2 !font-bold !mb-2"
              >
                {item.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="line-clamp-3"
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              />
            </CardContent>
            <CardContent className="!pt-0 flex gap-4">
              <Chip
                label={item.status === "publish" ? "Publish" : "Draft"}
                color={item.status === "publish" ? "primary" : "secondary"}
              />
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => openDeleteDialog(item.id)}
              >
                Hapus
              </Button>
            </CardContent>
          </Card>
        ))}
      </div>

      <div className="mt-6 flex justify-center">
        <TablePagination
          component="div"
          count={totalPages * rowsPerPage}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </div>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Konfirmasi Hapus</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Apakah Anda yakin ingin menghapus berita ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="secondary">
            Batal
          </Button>
          <Button onClick={handleDelete} color="error">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
