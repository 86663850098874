import { method } from "lodash";
import { fetcher } from "./apiClient";

export const uploadFile = async (data: any) => {
  const isFormData = data instanceof FormData;

  return fetcher("/file/upload", {
    method: "POST",
    body: data,
    headers: isFormData
      ? { "Content-Type": "multipart/form-data" }
      : { "Content-Type": "application/json" },
  });
};


export const removeFile = async (path: string) => {
  return fetcher("/file/remove", {
    method: "DELETE",
    body: {file: path}
  });
};
