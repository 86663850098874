import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as Yup from "yup"
import { getContact, saveContact } from '../../api/footer';
import { Alert, Button, Snackbar, TextField, Typography } from '@mui/material';
import FieldWithLabel from '../FieldWithLabel';
import { useLoader } from '../../context/loaderContext';

interface FormContact {
  phone: string;
  email: string;
  wa: string;
}

export default function Contact() {
  const { showLoader, hideLoader } = useLoader()
  const [isEdit, setIsEdit] = useState(false)

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const contactSchema = Yup.object({
    phone: Yup.string().required("wajib diisi"),
    email: Yup.string().required("wajib diisi"),
    wa: Yup.string().required("wajib diisi"),
  })

  const {
    register: registerContact,
    handleSubmit: handleSubmitContact,
    setValue: setValContact,
    getValues: getValContact,
    formState: { errors: errorContact },
    control: controllContact,
  } = useForm<FormContact>({
    resolver: yupResolver(contactSchema),
    defaultValues: isEdit ? undefined : {
      phone: "",
      email: "",
      wa: "",
    },
  });

  const onSubmitContact = async (data: FormContact) => {
    try {
      const payload = { ...data }
      const resSaveContact = await saveContact(payload)
      if (!resSaveContact.status) {
        setSnackbar({
          open: true,
          message: "Data kontak gagal disimpan",
          severity: "error",
        });
        return
      }

      setSnackbar({
        open: true,
        message: "Data kontak berhasil disimpan",
        severity: "success",
      });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    showLoader()
    getContact()
      .then((res: any) => {
        if (res.status) {
          setValContact('phone', res?.data.phone)
          setValContact('email', res?.data.email)
          setValContact('wa', res?.data.wa)
          setIsEdit(true)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        hideLoader()
      })
  }, [])

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form
        onSubmit={handleSubmitContact(onSubmitContact)}
        className="w-full flex flex-col gap-y-4 mt-4"
      >
        <FieldWithLabel label="No Telepon" required>
          <TextField
            className="!w-[400px]"
            {...registerContact("phone")}
            error={!!errorContact.phone}
            helperText={errorContact.phone?.message}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Email" required>
          <TextField
            className="!w-[400px]"
            {...registerContact("email")}
            error={!!errorContact.email}
            helperText={errorContact.email?.message}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Whatsapp" required>
          <TextField
            className="!w-[400px]"
            {...registerContact("wa")}
            error={!!errorContact.wa}
            helperText={errorContact.wa?.message}
          />
        </FieldWithLabel>
        <div className="flex justify-end mt-4">
          <Button type="submit" className="!w-fit">
            <Typography textTransform={"capitalize"}>
              Simpan
            </Typography>
          </Button>
        </div>
      </form>
    </>
  )
}
