import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Snackbar,
  Alert,
  Divider,
  Card,
  Tab,
  Tabs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ImageCropper from "../components/ImageCropper";
import FieldWithLabel from "../components/FieldWithLabel";
import QuillEditor from "../components/QuillEditor";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconEmptyTestimoni from "../assets/icon/ic-empty-testimoni.png";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import { getDropProgram } from "../api/dropdown";

import FileUploader from "../components/FileUploader";
import { uploadFile } from "../api/file";
import { addNews, getNewsById, editNews } from "../api/news";

import { useLoader } from "../context/loaderContext";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

// Date Picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileUploaderSingle from "../components/FileUploaderSingle";
import NewsPreview from "../components/NewsPreview";

type Testimoni = {
  name: string;
  from: string;
  testimoni: string;
};

type FormData = {
  title_id: string;
  title_en: string;
  content_id: string;
  content_en: string;
  program: string;
  image: string;
  date: string;
  status: string;
};

const validationSchema = Yup.object({
  title_id: Yup.string().required("Judul Artikel wajib diisi"),
  title_en: Yup.string().required("Judul Artikel wajib diisi"),
  content_id: Yup.string().required("Content wajib diisi"),
  content_en: Yup.string().required("Content wajib diisi"),
  program: Yup.string().required("Kategori wajib dipilih"),
  image: Yup.string().nullable().required("Cover Foto wajib diunggah"),
  date: Yup.string().required("Tanggal wajib dipilih"),
  status: Yup.string().required("Status wajib dipilih"),
});

const News: React.FC = () => {
  const { showLoader, hideLoader } = useLoader();
  const { id } = useParams();
  const navigate = useNavigate();

  const isEditMode = id !== "add";

  const [testimonials, setTestimonials] = useState<Testimoni[]>([]);
  const [testimoniBanner, setTestimoniBanner] = useState<File | null>(null);
  const [previewTestimoniBanner, setPreviewTestimoniBanner] =
    useState<string>("");
  const [initialGaleries, setInitialGaleries] = useState<any>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  const [ddProgram, setDdProgram] = useState<any>([]);
  // const [ddSubProgram, setDdSubProgram] = useState<any>([]);
  const [testimonial, setTestimonial] = useState<Testimoni>({
    name: "",
    from: "",
    testimoni: "",
  });

  const [showPreview, setShowPreview] = useState(false);
  const [tabTestimoni, setTabTestimoni] = useState<string>("id");
  const changeTabTestimoni = (_: React.SyntheticEvent, newValue: string) => {
    setTabTestimoni(newValue);
  };

  const [showPreviewDatas, setShowPreviewDatas] = useState<any>(null);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const defaultValues = {
    title_id: "",
    title_en: "",
    content_id: "",
    content_en: "",
    program: "",
    testimoni: "",
    // program_sub: "",
    image: "",
    date: "",
    status: "draft",
  };

  const formMethods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: isEditMode ? undefined : defaultValues,
  });

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    // watch,
    formState: { errors, isDirty },
    control,
  } = formMethods;

  const handleBack = () => {
    if (
      isDirty &&
      !window.confirm(
        "Apakah Anda yakin ingin kembali? Perubahan yang belum disimpan akan hilang"
      )
    ) {
      return;
    }
    navigate(`/news`);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      showLoader();

      try {
        const programResponse = await getDropProgram();
        setDdProgram(programResponse.data);

        if (isEditMode && id) {
          const newsResponse = await getNewsById(id);
          const newsData = newsResponse.data;

          setValue("title_id", newsData.title_id);
          setValue("title_en", newsData.title_en);
          setValue("content_id", newsData.content_id);
          setValue("content_en", newsData.content_en);
          setValue("program", newsData.program);
          // setValue("program_sub", newsData.program_sub);
          setValue("image", newsData.image);
          setPreviewTestimoniBanner(newsData.testimoni_banner);
          setValue("date", newsData.date);
          setValue("status", newsData.status);

          setTestimonials(newsData.testimonies);
          setInitialGaleries(newsData.galeries);

          // const subProgramResponse = await getDropSubProgram(newsData.program);
          // setDdSubProgram(subProgramResponse.data);
        }
      } catch (error) {
        console.error("Error fetching initial data", error);
      } finally {
        hideLoader();
      }
    };

    fetchInitialData();
  }, [id, isEditMode, setValue, showLoader, hideLoader, navigate]);

  // useEffect(() => {
  //   if (selectedProgram) {
  //     getDropSubProgram(selectedProgram).then((res) => {
  //       setDdSubProgram(res.data);
  //     });
  //   } else {
  //     setDdSubProgram([]);
  //   }
  // }, [selectedProgram]);

  const handleFileChange = (files: any[], type: string) => {
    if (type === "preview") {
      setInitialGaleries(files);
      return;
    }
    setUploadedFiles(files);
  };

  const handleFileChangeTestimoni = (data: any) => {
    if (!data) {
      return setPreviewTestimoniBanner("");
    }
    setTestimoniBanner(data?.file);
  };

  const handleCroppedImage = (image: string) => {
    setValue("image", image);
  };

  const handleAddTestimonial = () => {
    if (!testimonial.name || !testimonial.from || !testimonial.testimoni) {
      setSnackbar({
        open: true,
        message: "Isi semua form testimoni.",
        severity: "error",
      });
      return;
    }
    setTestimonials((prev) => [...prev, testimonial]);
    setTestimonial({ name: "", from: "", testimoni: "" });
    setSnackbar({
      open: true,
      message: "Testimoni berhasil ditambahkan.",
      severity: "success",
    });
  };

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") return; // Avoid closing on click outside
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleDeleteTestimonial = (index: number) => {
    setTestimonials((prev) => prev.filter((_, i) => i !== index));
  };

  const onSubmit = async (data: FormData) => {
    showLoader();

    try {
      let formData: any = {
        ...data,
        testimonies: [...testimonials],
        testimoni_banner: previewTestimoniBanner,
        galeries: [],
      };

      if (initialGaleries.length > 0) {
        formData.galeries = initialGaleries.map((galeri: any) => ({
          image: galeri.image,
          caption: galeri.caption,
        }));
      }

      if (uploadedFiles.length > 0) {
        for await (const galeri of uploadedFiles) {
          try {
            if (galeri.file instanceof File) {
              const fD = new FormData();
              fD.append("file", galeri.file);

              const result = await uploadFile(fD);
              const path = result.data as string;
              formData.galeries.push({
                image: path,
                caption: galeri.caption || "",
              });
            }
          } catch (error) {
            console.error("Error uploading file", error);
          }
        }
      }

      if (formData.image) {
        try {
          if (
            typeof formData.image === "string" &&
            formData.image.startsWith("data:")
          ) {
            const fD = new FormData();
            const blob = await fetch(formData.image).then((res) => res.blob());
            fD.append("file", blob);

            const result = await uploadFile(fD);
            const path = result.data as string;
            formData.image = path;
          }
        } catch (error) {
          console.error("Error uploading file", error);
        }
      }

      if (testimoniBanner && testimoniBanner instanceof File) {
        try {
          const fD = new FormData();
          fD.append("file", testimoniBanner);

          const result = await uploadFile(fD);
          const path = result.data as string;
          formData.testimoni_banner = path;
        } catch (error) {
          console.error("Error uploading file", error);
        }
      }

      if (isEditMode && id) {
        await editNews(id, formData);
        setSnackbar({
          open: true,
          message: "Berita berhasil diperbarui",
          severity: "success",
        });
      } else {
        await addNews(formData);
        setSnackbar({
          open: true,
          message: "Berita berhasil ditambahkan",
          severity: "success",
        });
      }

      navigate(`/news`);
    } catch (error) {
      console.error("Error submitting form", error);
      setSnackbar({
        open: true,
        message: "Terjadi kesalahan",
        severity: "error",
      });
    } finally {
      hideLoader();
    }
  };

  const showPreviewData = () => {
    let formData: any = {
      ...getValues(),
      testimonies: [...testimonials],
      testimoni_banner: previewTestimoniBanner ? previewTestimoniBanner : testimoniBanner,
      galeries: [],
    };

    if (initialGaleries.length > 0) {
      initialGaleries.forEach((galeri: any) => {
        formData.galeries.push({
          file: galeri.image,
          caption: galeri.caption || "",
        });
      });
    }

    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((galeri: any) => {
        formData.galeries.push({
          file: galeri.file,
          caption: galeri.caption || "",
        });
      });
    }

    setShowPreviewDatas(formData);
    setShowPreview(true);
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <div className="bg-neutral-100">
        <div className="mb-8">
          <div
            className="text-blue-600 cursor-pointer font-bold text-xs mb-2 flex items-center"
            onClick={handleBack}
          >
            <ArrowBackIcon fontSize="small" className="mr-2" />
            BACK
          </div>
          <h1 className="font-bold text-3xl mb-2">News</h1>
          <p className="text-sm text-neutral-600">
            Pembuatan News Untuk Pelaksanaan Donasi
          </p>
        </div>

        <Box className="bg-white pt-2 pb-6 px-6 flex flex-col rounded items-center shadow-md gap-y-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-y-4 mt-4"
          >
            <FieldWithLabel label="Cover Foto" required applyMargin={true}>
              <Box className="flex flex-col gap-2 !w-full">
                <ImageCropper
                  onCrop={handleCroppedImage}
                  defaultImage={getValues("image")}
                />
                {errors.image && (
                  <Typography variant="caption" color="error">
                    {errors.image.message}
                  </Typography>
                )}
              </Box>
            </FieldWithLabel>
            <div className="flex gap-8">
              <div className="w-1/3">
                <FieldWithLabel
                  label="Pilih Kategori"
                  required
                  applyMargin={true}
                >
                  <Controller
                    name="program"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        error={!!errors.program}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          // setValue("program_sub", "");
                        }}
                      >
                        {ddProgram.map((program: any, idx: number) => (
                          <MenuItem key={idx} value={program.key}>
                            {program.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.program && (
                    <Typography variant="caption" color="error">
                      {errors.program.message}
                    </Typography>
                  )}
                </FieldWithLabel>
              </div>
              <div className="w-2/3">
                <FieldWithLabel label="Status" required applyMargin={true}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        value={field.value || "publish"}
                      >
                        <FormControlLabel
                          value="publish"
                          control={<Radio />}
                          label="Publish"
                        />
                        <FormControlLabel
                          value="draft"
                          control={<Radio />}
                          label="Draft"
                        />
                      </RadioGroup>
                    )}
                  />

                  {errors.status && (
                    <Typography variant="caption" color="error">
                      {errors.status?.message}
                    </Typography>
                  )}
                </FieldWithLabel>
              </div>
            </div>

            {/* {selectedProgram && ( */}
            <FieldWithLabel label="Terkait dengan program donasi">
              <TextField disabled value={"-"} />
            </FieldWithLabel>
            {/* )} */}

            <FieldWithLabel label="Tanggal Kegiatan" required>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="id"
              >
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <DatePicker
                      format="DD MMMM YYYY"
                      value={
                        getValues("date") ? dayjs(getValues("date")) : null
                      }
                      onChange={(date) => field.onChange(date)}
                    />
                  )}
                />
                {errors.date && (
                  <Typography variant="caption" color="error">
                    {errors.date.message}
                  </Typography>
                )}
              </LocalizationProvider>
            </FieldWithLabel>

            <FieldWithLabel label="Artikel" applyMargin={true}>
              <Box className="border p-4 rounded shadow-sm">
                <Box className="bg-white rounded items-center gap-y-4">
                  <Tabs
                    value={tabTestimoni ?? "id"}
                    onChange={changeTabTestimoni}
                    className="mb-4"
                  >
                    <Tab value="id" label="Bahasa Indonesia" />
                    <Tab value="en" label="Bahasa Inggris" />
                  </Tabs>

                  {/* Bahasa Indonesia Tab */}
                  <div className={tabTestimoni === "id" ? "block" : "hidden"}>
                    <FieldWithLabel label="Judul Artikel" required>
                      <TextField
                        {...register("title_id")}
                        error={!!errors.title_id}
                        helperText={errors.title_id?.message}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel label="Artikel" required applyMargin={true}>
                      <QuillEditor name="content_id" control={control} />
                    </FieldWithLabel>
                  </div>

                  {/* Bahasa Inggris Tab */}
                  <div className={tabTestimoni === "en" ? "block" : "hidden"}>
                    <FieldWithLabel label="Judul Artikel" required>
                      <TextField
                        {...register("title_en")}
                        error={!!errors.title_en}
                        helperText={errors.title_en?.message}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel label="Artikel" required applyMargin={true}>
                      <QuillEditor name="content_en" control={control} />
                    </FieldWithLabel>
                  </div>
                </Box>
              </Box>
            </FieldWithLabel>

            {/* Testimonial Section */}
            <FieldWithLabel label="Testimoni" applyMargin={true}>
              <Box className="border p-4 rounded shadow-sm">
                <Box className="bg-white rounded items-center gap-y-4">
                  <FieldWithLabel label="Cover Testimoni" applyMargin={true}>
                    <Box className="flex flex-col gap-2 !w-full mb-4">
                      <FileUploaderSingle
                        onChange={handleFileChangeTestimoni}
                        initialFile={previewTestimoniBanner}
                      />
                    </Box>
                  </FieldWithLabel>

                  <div className="grid grid-cols-2 gap-6">
                    <FieldWithLabel label="Nama">
                      <TextField
                        value={testimonial.name}
                        onChange={(e) =>
                          setTestimonial((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                      />
                    </FieldWithLabel>
                    <FieldWithLabel label="Asal">
                      <TextField
                        value={testimonial.from}
                        onChange={(e) =>
                          setTestimonial((prev) => ({
                            ...prev,
                            from: e.target.value,
                          }))
                        }
                      />
                    </FieldWithLabel>
                  </div>

                  <FieldWithLabel label="Testimoni" applyMargin={true}>
                    <TextareaAutosize
                      minRows={4}
                      value={testimonial.testimoni}
                      onChange={(e) =>
                        setTestimonial((prev) => ({
                          ...prev,
                          testimoni: e.target.value,
                        }))
                      }
                      className="border w-full rounded p-2"
                    />
                  </FieldWithLabel>

                  <div className="flex justify-end">
                    <Button
                      className="!mt-6 !w-fit"
                      variant="outlined"
                      type="button"
                      size="small"
                      onClick={handleAddTestimonial}
                    >
                      <Typography textTransform={"capitalize"}>
                        Tambah Testimoni
                      </Typography>
                    </Button>
                  </div>
                </Box>
                <Divider className="!my-6" />

                {testimonials.length > 0 ? (
                  <div className="grid grid-cols-1 gap-y-4">
                    {testimonials.map((item, index) => (
                      <Card
                        key={index}
                        variant="outlined"
                        className="p-4 flex flex-col gap-y-4 text-sm"
                      >
                        <div className="">"{item.testimoni}"</div>
                        <div className="flex gap-4 justify-between">
                          <div className="flex-1">
                            <div className="text-base font-bold">
                              {item.name}
                            </div>
                            <div>{item.from}</div>
                          </div>

                          <Button
                            onClick={() => handleDeleteTestimonial(index)}
                            className="!w-fit"
                            color="inherit"
                            variant="outlined"
                          >
                            <DeleteSharpIcon />
                          </Button>
                        </div>
                      </Card>
                    ))}
                  </div>
                ) : (
                  <div className="text-center mt-8">
                    <img
                      src={IconEmptyTestimoni}
                      alt="empty"
                      className="w-36 mx-auto mb-4"
                    />
                    <div className="text-neutral-600">Testimoni kosong</div>
                  </div>
                )}
              </Box>
            </FieldWithLabel>

            <FileUploader
              onChange={handleFileChange}
              initialFiles={[...initialGaleries]}
            />
            <div className="flex justify-end gap-4">
              <Button
                className="!w-fit hover:bg-neutral-100 border-[1.5px] !border-primary-700"
                color="secondary"
                onClick={() => showPreviewData()}
              >
                <Typography textTransform={"capitalize"}>Preview</Typography>
              </Button>

              <Button type="submit" className="!w-fit">
                <Typography textTransform={"capitalize"}>
                  Simpan Berita
                </Typography>
              </Button>
            </div>
          </form>
        </Box>
      </div>

      <Dialog
        fullScreen
        className="max-w-5xl mx-auto"
        open={showPreview}
        onClose={() => setShowPreview(false)}
      >
        <DialogTitle className="flex justify-between">
          Preview News
          <IconButton color="inherit" onClick={() => setShowPreview(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <NewsPreview dataNews={{ ...showPreviewDatas }} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default News;
