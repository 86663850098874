import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";

import ProtectedRoute from "../components/ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import News from "../pages/News";
import NewsDetail from "../pages/NewsDetail";
import AdminLayout from "../components/AdminLayout";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Cookies from "js-cookie";
import YayasanGSN from "../pages/YayasanGSN";
import BerandaPage from "../pages/Beranda";
import FooterPage from "../pages/Footer";
import BergabungPage from "../pages/Bergabung";

const isLogged = () => {
  return Cookies.get("_token_");
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "news",
        element: <News />,
      },
      {
        path: "beranda",
        element: <BerandaPage />,
      },
      {
        path: "bergabung",
        element: <BergabungPage />,
      },
      {
        path: "footer",
        element: <FooterPage />,
      },
      {
        path: "news/:id",
        element: <NewsDetail />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "yayasan",
        element: <YayasanGSN />,
      },
    ],
  },
  {
    path: "/login",
    element: isLogged() ? <Navigate to={"/news"} replace /> : <Login />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
