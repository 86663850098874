import { fetcher } from "./apiClient";

export const getHomeData = async () => {
  return await fetcher("/cms/home")
}

export const saveHomeData = async (payload :any) => {
  return await fetcher("/cms/home", {
    body : payload,
    method  :"PUT"
  })
}