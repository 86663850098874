import { fetcher } from "./apiClient";

export const getMitraContent = async () => {
  return await fetcher("/cms/join-us/partner")
} 

export const saveMitraContent = async (payload : any) => {
  return await fetcher("/cms/join-us/partner", {
    method : "PUT",
    body : payload
  })
} 


export const getTermContent = async () => {
  return await fetcher("/cms/join-us/term-condition")
} 

export const saveTermContent = async (payload : any) => {
  return await fetcher("/cms/join-us/term-condition", {
    method : "PUT",
    body : payload
  })
} 