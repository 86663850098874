import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as Yup from "yup"
import { getAddress, saveAddress } from '../../api/footer';
import { Alert, Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import FieldWithLabel from '../FieldWithLabel';
import QuillEditor from '../QuillEditor';
import { useLoader } from '../../context/loaderContext';

interface FormAddress {
  address: any;
}

const addressSchema = Yup.object({
  address: Yup.string().required("Alamat wajib diisi")
})

export default function Address() {
  const { showLoader, hideLoader } = useLoader()
  const [isEdit, setIsEdit] = useState(false)

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const {
    register: registerAddress,
    handleSubmit: handleSubmitAddress,
    setValue: setValAddress,
    getValues: getValAddress,
    formState: { errors: errorAddress },
    control: controllAddress,
  } = useForm<FormAddress>({
    resolver: yupResolver(addressSchema),
    defaultValues: isEdit ? undefined : {
      address: "",
    },
  });

  const onSubmitAddress = async (data: FormAddress) => {
    try {
      const payload = { ...data }
      const resSaveAddress = await saveAddress(payload)
      if (!resSaveAddress.status) {
        setSnackbar({
          open: true,
          message: "Data alamat gagal disimpan",
          severity: "error",
        });
        return
      }

      setSnackbar({
        open: true,
        message: "Data alamat berhasil disimpan",
        severity: "success",
      });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    showLoader()
    getAddress().then((res: any) => {
      if (res.status) {
        setValAddress('address', res?.data.address)
        setIsEdit(true)
      }
    })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        hideLoader()
      })
  }, [])

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form
        onSubmit={handleSubmitAddress(onSubmitAddress)}
        className="w-full flex flex-col gap-y-4 mt-4"
      >
        <FieldWithLabel label="Alamat" required applyMargin={true}>
          <Box>
            <QuillEditor name="address" control={controllAddress} />
          </Box>
        </FieldWithLabel>
        <div className="flex justify-end mt-4">
          <Button type="submit" className="!w-fit">
            <Typography textTransform={"capitalize"}>
              Simpan
            </Typography>
          </Button>
        </div>
      </form>
    </>
  )
}
