import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
} from "@mui/material";

import { LoaderProvider } from "../context/loaderContext";

import IconLogout from "../assets/icon/ic-logout.svg";
import Logo from "../assets/logo.png";
import Cookies from "js-cookie";

import Accent from "../assets/accent.png";
import navbar from "../constant/navbar";

const AdminLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const route = window.location.pathname?.split("/")[1];
  const [selectedIndex, setSelectedIndex] = React.useState(route);
  const navigate = useNavigate();

  console.log(route, "-0--0-0-0");

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: string
  ) => {
    if (index === "logout") {
      Cookies.remove("_token_");
      Cookies.remove("_refreshToken_");
      setTimeout(() => {
        navigate("/login");
      }, 200);
      return;
    }
    setSelectedIndex(index);
    navigate(`/${index}`);
  };

  return (
    <LoaderProvider>
      <div className="admin-layout flex min-h-screen">
        <Drawer
          variant="permanent"
          className="!z-0"
          sx={{
            width: 275,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 275,
              height: "100vh",
              boxSizing: "border-box",
            },
          }}
        >
          <div
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <ListItem alignItems="flex-start" className="!py-4">
              <ListItemAvatar>
                <img alt="Logo GSN" src={Logo} className="size-9" />
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <>
                    <div className="font-bold text-base text-neutral-1000">
                      GSN DASHBOARD
                    </div>
                    <div className="text-sm text-neutral-600">
                      Sistem Manajemen Donasi
                    </div>
                  </>
                }
              />
            </ListItem>

            <Divider />

            <ListItem className="flex-col" alignItems="flex-start">
              <div className="font-bold text-sm text-neutral-600">
                ADMIN
              </div>
              <div className="text-xs text-neutral-600">
                CMS Administrator
              </div>
            </ListItem>

            <Divider />

            <List className="!mx-2 flex flex-col gap-y-2">
              {navbar.map((nav, navIdx) => {
                return (
                  <ListItemButton
                    key={`${nav.label}-${navIdx}`}
                    className={
                      selectedIndex === nav.key
                        ? "!bg-primary-600 !text-white !rounded"
                        : ""
                    }
                    selected={selectedIndex === nav.key}
                    onClick={(event) => handleListItemClick(event, nav.key)}
                  >
                    <ListItemIcon>
                      {selectedIndex === nav.key ? (
                        <img
                          src={nav.iconDark}
                          alt={`ic-${nav.key}`}
                          className="!text-white size-5"
                        />
                      ) : (
                        <img src={nav.icon} alt={`ic-${nav.key}`} className="size-4" />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={nav.label} />
                  </ListItemButton>
                );
              })}

              <ListItemButton
                onClick={(event) => handleListItemClick(event, "logout")}
              >
                <ListItemIcon>
                  <img src={IconLogout} alt="ic-logout" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </div>
        </Drawer>

        <main className="p-6 bg-neutral-100" style={{ flex: 1 }}>
          <img
            src={Accent}
            alt="accent"
            className="absolute top-0 right-0 w-[150px]"
          />
          {children}
        </main>
      </div>
    </LoaderProvider>
  );
};

export default AdminLayout;
