import { fetcher } from "./apiClient";

export const addNews = async (data: any) => {
  return fetcher("/master/news", {
    method: "POST",
    body: data,
  });
};

export const editNews = async (newsId: string | number, data: any) => {
  return fetcher("/master/news/" + newsId, {
    method: "PUT",
    body: data,
  });
};

export const getNews = async (params?: any) => {
  return fetcher("/master/news", { params: { ...params } });
};

export const getNewsById = async (newsId: string | number) => {
  return fetcher("/master/news/" + newsId);
};

export const deleteNews = async (newsId: string | number) => {
  return fetcher("/master/news/" + newsId, {
    method: "DELETE",
  });
};

export const getBanner = () => {
  return fetcher("/cms/news/banner");
};

export const setBannerData = async (data: any) => {
  return fetcher("/cms/news/banner", {
    method: "PUT",
    body: data,
  });
};