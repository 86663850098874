import IconNewsDark from "../assets/icon/ic-news-dark.svg";
import IconNews from "../assets/icon/ic-news.svg";
import IconBerandaDark from "../assets/icon/ic-beranda-dark.svg";
import IconBeranda from "../assets/icon/ic-beranda.svg";
import IconFooterDark from "../assets/icon/ic-footer-dark.svg";
import IconFooter from "../assets/icon/ic-footer.svg";
import IconBergabungDark from "../assets/icon/ic-bergabung-dark.svg";
import IconBergabung from "../assets/icon/ic-bergabung.svg";
import IconProgramDark from "../assets/icon/ic-program-dark.svg";
import IconProgram from "../assets/icon/ic-program.svg";

const navbar = [
  {
    label: "Halaman Beranda",
    key: "beranda",
    icon: IconBeranda,
    iconDark: IconBerandaDark,
  },
  {
    label: "Yayasan GSN",
    key: "yayasan",
    icon: IconNews,
    iconDark: IconNewsDark,
  },
  {
    label: "Program",
    key: "news",
    icon: IconProgram,
    iconDark: IconProgramDark,
  },
  {
    label: "Footer",
    key: "footer",
    icon: IconFooter,
    iconDark: IconFooterDark,
  },
  {
    label: "Bergabung Dengan Kami",
    key: "bergabung",
    icon: IconBergabung,
    iconDark: IconBergabungDark,
  },
];

export default navbar;
