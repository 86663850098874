import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as Yup from "yup"
import { Alert, Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import FieldWithLabel from '../FieldWithLabel';
import QuillEditor from '../QuillEditor';
import { useLoader } from '../../context/loaderContext';
import { saveMitraContent, getMitraContent } from '../../api/bergabung';

interface FormData {
  title: {
    id: string;
    en: string;
  };
  article: {
    id: string;
    en: string;
  }
}

const validationSchema = Yup.object({
  title: Yup.object().shape({
    id: Yup.string().nullable(''),
    en: Yup.string().nullable(''),
  }),
  article: Yup.object().shape({
    id: Yup.string().nullable(''),
    en: Yup.string().nullable(),
  }),
})

const defaultValues = {
  title: {
    id: "",
    en: "",
  },
  article: {
    id: "",
    en: "",
  }
}


export default function Mitra() {
  const { showLoader, hideLoader } = useLoader()
  const [isEdit, setIsEdit] = useState(false)

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<FormData>({
    //@ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: isEdit ? undefined : defaultValues,
  });

  const onSubmit = async (data: FormData) => {
    try {
      const payload = { ...data }
      const resSaveMitra = await saveMitraContent(payload)
      if (!resSaveMitra.status) {
        setSnackbar({
          open: true,
          message: "Konten artikel gagal disimpan",
          severity: "error",
        });
        return
      }

      setSnackbar({
        open: true,
        message: "Konten artikel berhasil disimpan",
        severity: "success",
      });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    showLoader()
    getMitraContent().then((res: any) => {
      if (res.status) {
        setValue('title.id', res?.data.title.id)
        setValue('title.en', res?.data.title.en)
        setValue('article.id', res?.data.article.id)
        setValue('article.en', res?.data.article.en)
        setIsEdit(true)
      }
    })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        hideLoader()
      })
  }, [])

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-y-4 mt-4"
      >
        <div className='mb-8'>
          <FieldWithLabel label="Judul (Bahasa Indonesia)" required>
            <TextField
              {...register("title.id")}
              error={!!errors?.title?.id}
              helperText={errors?.title?.id?.message}
            />
          </FieldWithLabel>
          <FieldWithLabel label="Artikel (Bahasa Indonesia)" required applyMargin={true}>
            <Box>
              <QuillEditor name="article.id" control={control} />
            </Box>
          </FieldWithLabel>
        </div>
        <div>
          <FieldWithLabel label="Judul (Bahasa Ingrris)" required>
            <TextField
              {...register("title.en")}
              error={!!errors?.title?.en}
              helperText={errors?.title?.en?.message}
            />
          </FieldWithLabel>
          <FieldWithLabel label="Artikel (Bahasa Inggris)" required applyMargin={true}>
            <Box>
              <QuillEditor name="article.en" control={control} />
            </Box>
          </FieldWithLabel>
        </div>
        <div className="flex justify-end mt-4">
          <Button type="submit" className="!w-fit">
            <Typography textTransform={"capitalize"}>
              Simpan
            </Typography>
          </Button>
        </div>
      </form>
    </>
  )
}
