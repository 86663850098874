import { fetcher } from "./apiClient";

export const getLogoFooter = async () => {
  return await fetcher("/cms/footer/logo")
}

export const saveLogoFooter = async (payload:any) => {
  console.log(payload,"+_=-+_+_+_+_")
  return await fetcher("/cms/footer/logo", {
    method : "PUT",
    body : payload
  })
}

export const getAddress = async () => {
  return await fetcher("/cms/footer/address")
}

export const saveAddress = async (payload:any) => {
  return await fetcher("/cms/footer/address", {
    method : "PUT",
    body : payload
  })
}

export const getContact = async () => {
  return await fetcher("/cms/footer/contact")
}

export const saveContact = async (payload:any) => {
  return await fetcher("/cms/footer/contact", {
    method : "PUT",
    body : payload
  })
}

export const getSocmed = async () => {
  return await fetcher("/cms/footer/sosmed")
}

export const saveSocmed = async (payload:any) => {
  return await fetcher("/cms/footer/sosmed", {
    method : "PUT",
    body : payload
  })
}