import { fetcher } from "./apiClient";

export const getMasterProgram = async (params?: any) => {
  return fetcher("/master/program", { params: { ...params } });
};

export const addMasterProgram = async (data: any) => {
  return fetcher("/master/program", {
    method: "POST",
    body: data,
  });
};

export const editMasterProgram = async (
  programId: string | number,
  data: any
) => {
  return fetcher("/master/program/" + programId, {
    method: "PUT",
    body: data,
  });
};

export const getMasterProgramById = async (programId: string | number) => {
  return fetcher("/master/program/" + programId);
};

export const deleteMasterProgram = async (programId: string | number) => {
  return fetcher("/master/program/" + programId, {
    method: "DELETE",
  });
};

export const getMasterProgramSub = async (params?: any) => {
  return fetcher("/master/program-sub", { params: { ...params } });
};

export const addMasterProgramSub = async (data: any) => {
  return fetcher("/master/program-sub", {
    method: "POST",
    body: data,
  });
};

export const editMasterProgramSub = async (
  programId: string | number,
  data: any
) => {
  return fetcher("/master/program-sub/" + programId, {
    method: "PUT",
    body: data,
  });
};

export const getMasterProgramByIdSub = async (programId: string | number) => {
  return fetcher("/master/program-sub/" + programId);
};

export const deleteMasterProgramSub= async (programId: string | number) => {
  return fetcher("/master/program-sub/" + programId, {
    method: "DELETE",
  });
};
