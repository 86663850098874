"use client"

import React, { useState } from "react";
import { TextField, Button, Typography, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Logo from "../assets/logo-white.png";

import axios from "axios";
import Cookies from "js-cookie";

import FieldWithLabel from "../components/FieldWithLabel";

const validationSchema = Yup.object({
  username: Yup.string().required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

type FormData = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`
  })
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const handleLogin = async (payload: FormData) => {
    try {
      const { username, password } = payload;
      const { data } = await api.post(`/auth/login`, {
        email: username,
        password,
      })

      if (!data.status) {
        throw data.error
      }

      Cookies.set("_token_", data.data.auth.access_token, {
        expires: data.data.auth.expires_in,
      });
      Cookies.set("_refreshToken_", data.data.auth.refresh.token, {
        expires: data.data.auth.refresh.exp,
      });

      navigate("/news")
    } catch (error) {
      console.log(error)
      alert("Invalid credentials");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="bg-neutral-100 h-screen flex justify-center items-center">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 6,
          borderRadius: 1,
          boxShadow: 3,
        }}
        width={"560px"}
      >
        <img alt="Logo GSN White" src={Logo} className="size-20 mb-4" />
        <Typography variant="h5">
          <span className="text-2xl">Masuk Akun</span>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Sistem Management Donasi
        </Typography>

        <form onSubmit={handleSubmit(handleLogin)} className="w-full flex flex-col gap-2 mt-4">
          <FieldWithLabel label="Username" required>
            <TextField
              {...register("username")}
              error={!!errors.username}
              helperText={errors.username?.message}
            />
          </FieldWithLabel>

          <FieldWithLabel label="Password" required>
            <TextField
              type={showPassword ? "text" : "password"}
              {...register("password")}
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </FieldWithLabel>

          <Button type="submit" sx={{ marginTop: 2 }}>
            Login
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Login;
