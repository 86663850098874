import React from "react";

interface FieldWithLabelProps {
  label: string;
  required?: boolean;
  applyMargin?: boolean;
  children: React.ReactNode;
}

const FieldWithLabel: React.FC<FieldWithLabelProps> = ({
  label,
  required = false,
  applyMargin = false,
  children,
}) => {
  return (
    <div className="flex flex-col w-full">
      <label className={`text-xs font-semibold ${applyMargin ? "mb-2" : "-mb-2"}`}>
        {label}
        {required && <span className="text-primary-500">*</span>}
      </label>
      {children}
    </div>
  );
};

export default FieldWithLabel;
