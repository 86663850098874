import { useEffect, useState } from "react";
import FieldWithLabel from "../../components/FieldWithLabel";
import ImageCropper from "../../components/ImageCropper";
import { useLoader } from "../../context/loaderContext";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { uploadFile } from "../../api/file";
import { getBanner, setBannerData } from "../../api/program";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface FormData {
  hero: string;
}

const validationSchema = Yup.object({
  hero: Yup.string().defined(),
});

export default function YayasanBanner() {
  const { showLoader, hideLoader } = useLoader();

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const defaultValues = {
    hero: "",
  };

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const res = await getBanner();
        if (res.status) {
          setValue("hero", res.data.hero);
        }
      } catch (error) {
        console.error("Error fetching program:", error);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, [showLoader, hideLoader, setValue]);

  const handleCroppedHero = (image: string) => {
    setValue("hero", image);
  };

  const onSubmit = async () => {
    showLoader();
    try {
      let hero = getValues("hero") || "";
      if (hero) {
        try {
          if (typeof hero === "string" && hero.startsWith("data:")) {
            const fD = new FormData();
            const blob = await fetch(hero).then((res) => res.blob());
            fD.append("file", blob);

            const result = await uploadFile(fD);
            const path = result.data as string;
            hero = path;
          }
        } catch (error) {
          console.error("Error uploading file", error);
        }
      }

      const res = await setBannerData({ hero: hero });
      if (res.status) {
        setSnackbar({
          open: true,
          message: "Data berhasil diupdate.",
          severity: "success",
        });
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <div className="w-fit bg-white rounded-md p-4 px-6">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-y-4 mt-4"
        >
          <FieldWithLabel label="Banner" required applyMargin={true}>
            <Box className="flex flex-col gap-2">
              <ImageCropper
                onCrop={handleCroppedHero}
                resolution="free"
                defaultImage={getValues("hero")}
              />
              {errors.hero && (
                <Typography variant="caption" color="error">
                  {errors.hero.message}
                </Typography>
              )}
            </Box>
          </FieldWithLabel>
          <div className="flex justify-end mt-4">
            <Button
              className="!w-fit !capitalize"
              type="submit"
            >
              Simpan
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
