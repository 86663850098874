import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

interface UploadedFile {
  file: File | null;
  previewUrl: string;
}

interface FileUploaderProps {
  onChange: (file: UploadedFile | string | null) => void;
  initialFile: string | null | undefined;
  label?: string;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileUploaderSingle: React.FC<FileUploaderProps> = ({
  onChange,
  initialFile,
  label = "Unggah File",
}) => {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null | undefined>(null);

  useEffect(() => {
    setPreviewUrl(initialFile);
  }, [initialFile]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Validate file type (image or video)
      if (!["image/", "video/"].some((type) => file.type.startsWith(type))) {
        alert("Only image and video files are allowed.");
        return;
      }

      const newFile = {
        file,
        previewUrl: URL.createObjectURL(file),
      };

      setUploadedFile(newFile);
      onChange(newFile);

      // Reset input for new selection
      event.target.value = "";
    }
  };

  const handleRemoveFile = (isPreview: boolean) => {
    if (isPreview) {
      setPreviewUrl("");
      onChange("");
    } else {
      if (uploadedFile?.previewUrl) {
        URL.revokeObjectURL(uploadedFile.previewUrl); // Cleanup
      }
      setUploadedFile(null);
      onChange(null);
    }
  };

  // Function to check if the URL is a video or image
  const isVideo = (url: string): boolean =>
    /\.(mp4|mov|avi|mkv|webm)$/i.test(url);

  return (
    <div>
      {(!previewUrl && !uploadedFile) && (
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          {label}
          <VisuallyHiddenInput
            type="file"
            accept="image/*,video/*"
            onChange={handleFileChange}
          />
        </Button>
      )}

      <div className="mt-4">
        {previewUrl && (
          <div className="border rounded-lg p-4 flex flex-col items-center">
            <div className="mb-2">
              {isVideo(previewUrl) ? (
                <video
                  src={previewUrl}
                  className="h-56 rounded mx-auto"
                  controls
                />
              ) : (
                <img
                  src={previewUrl}
                  alt="Preview"
                  className="h-56 object-cover mx-auto rounded"
                />
              )}
            </div>

            <IconButton color="primary" onClick={() => handleRemoveFile(true)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}

        {uploadedFile && (
          <div className="border rounded-lg p-4 flex flex-col items-center">
            <div className="mb-2">
              {uploadedFile.file?.type.startsWith("image/") ? (
                <img
                  src={uploadedFile.previewUrl}
                  alt="Uploaded"
                  className="h-56 object-cover mx-auto rounded"
                />
              ) : (
                <video
                  src={uploadedFile.previewUrl}
                  className="h-56 rounded mx-auto"
                  controls
                />
              )}
            </div>

            <IconButton color="primary" onClick={() => handleRemoveFile(false)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploaderSingle;
