import { fetcher } from "./apiClient";

export const getMasterOrg = async (params?: any) => {
  return fetcher("/master/organization", { params: { ...params } });
};

export const addMasterOrg = async (data: any) => {
  return fetcher("/master/organization", {
    method: "POST",
    body: data,
  });
};

export const editMasterOrg = async (
  programId: string | number,
  data: any
) => {
  return fetcher("/master/organization/" + programId, {
    method: "PUT",
    body: data,
  });
};

export const getMasterOrgById = async (programId: string | number) => {
  return fetcher("/master/organization/" + programId);
};

export const deleteMasterOrg = async (programId: string | number) => {
  return fetcher("/master/organization/" + programId, {
    method: "DELETE",
  });
};

export const getMasterPosition = async (params?: any) => {
  return fetcher("/master/position", { params: { ...params } });
};

export const addMasterPosition = async (data: any) => {
  return fetcher("/master/position", {
    method: "POST",
    body: data,
  });
};

export const editMasterPosition = async (
  programId: string | number,
  data: any
) => {
  return fetcher("/master/position/" + programId, {
    method: "PUT",
    body: data,
  });
};

export const getMasterOrgByIdPosition = async (programId: string | number) => {
  return fetcher("/master/position/" + programId);
};

export const deleteMasterOrgPosition= async (programId: string | number) => {
  return fetcher("/master/position/" + programId, {
    method: "DELETE",
  });
};
