import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useController } from "react-hook-form";

interface QuillEditorProps {
  name: string;
  control: any;
}

const QuillEditor: React.FC<QuillEditorProps> = ({ name, control }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const quillRef = useRef<ReactQuill>(null);

  return (
    <>
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        theme="snow"
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ size: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["bold", "italic", "underline", "strike"],
            ["link", "image"],
            [{ color: [] }, { background: [] }],
            ["blockquote"],
            [{ direction: "rtl" }],
            ["clean"],
          ],
        }}
      />
      {error && <div className="text-red-500 text-xs mt-2">{error.message}</div>}
    </>
  );
};

export default QuillEditor;
