import { Alert, Box, Snackbar, Tab, Tabs, } from '@mui/material';
import React, { useState } from 'react'
import Socmed from '../components/footer/Socmed';
import Contact from '../components/footer/Contact';
import FormLogoFooter from '../components/footer/Logo';
import Address from '../components/footer/Address';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}

export default function FooterPage() {
  const [currentTab, setCurrentTab] = useState<string>("logo")
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };


  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== currentTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
        className='w-fit bg-white rounded-md'
      >
        {value === currentTab && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div className='w-full'>
        <div className="mb-8">
          <h1 className="font-bold text-3xl mb-2">Pengaturan Footer Websites</h1>
          <p className="text-sm text-neutral-600">
            Pengaturan Konten di Footer Website
          </p>
        </div>

        <Tabs value={currentTab} onChange={(e, value) => { setCurrentTab(value) }} className='w-full'>
          <Tab label="Logo" value={"logo"} />
          <Tab label="Alamat" value={"address"} />
          <Tab label="Hubungi Kami" value={"contact"} />
          <Tab label="Sosial Media" value={"socmed"} />
        </Tabs>

        <CustomTabPanel value={'logo'} index={0}>
          <FormLogoFooter />
        </CustomTabPanel>
        <CustomTabPanel value='address' index={1}>
          <Address />
        </CustomTabPanel>
        <CustomTabPanel value='contact' index={2}>
          <Contact />
        </CustomTabPanel>
        <CustomTabPanel value='socmed' index={3}>
          <Socmed />
        </CustomTabPanel>
      </div>
    </>
  )
}

