import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as Yup from "yup"
import { getContact, getLogoFooter, getSocmed, saveContact, saveLogoFooter, saveSocmed } from '../../api/footer';
import { Alert, Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import FieldWithLabel from '../FieldWithLabel';
import { removeFile, uploadFile } from '../../api/file';
import ImageCropper from '../ImageCropper';
import { useLoader } from '../../context/loaderContext';
interface FormLogo {
  logo: string;
}

export default function FormLogoFooter() {
  const { showLoader, hideLoader } = useLoader();
  const [isEdit, setIsEdit] = useState(false)

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };


  const logoSchema = Yup.object({
    logo: Yup.string().required("Logo wajib diisi")
  })

  const {
    handleSubmit: handleSubmitLogo,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormLogo>({
    resolver: yupResolver(logoSchema),
    defaultValues: isEdit ? undefined : {
      logo: "",
    },
  });

  const handleCroppedImage = (image: string) => {
    setValue("logo", image);
  };


  const onSubmitLogo = async (data: FormLogo) => {
    let pathUploaded: any;
    try {
      const payload = { ...data }
      const fD = new FormData();
      //@ts-ignore
      fD.append("file", payload.logo);
      const result = await uploadFile(fD);
      if (result.status) {
        const path = result.data as string;
        pathUploaded = path

        const resSaveLogoFooter = await saveLogoFooter({
          logo: path
        })

        if (!resSaveLogoFooter.status) {
          setSnackbar({
            open: true,
            message: "Data logo footer gagal disimpan",
            severity: "error",
          });
          return
        }

        setSnackbar({
          open: true,
          message: "Data logo footer berhasil disimpan",
          severity: "success",
        });
      }

    } catch (error) {
      console.log(error)
      if (pathUploaded) {
        await removeFile(pathUploaded)
      }
    }
  }

  useEffect(() => {
    showLoader()
    getLogoFooter()
      .then((res: any) => {
        if (res.status) {
          setValue('logo', res?.data.logo)
          setIsEdit(true)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        hideLoader()
      })
  }, [])

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form
        onSubmit={handleSubmitLogo(onSubmitLogo)}
        className="w-full flex flex-col gap-y-4 mt-4"
      >
        <FieldWithLabel label="Logo Footer" required applyMargin={true}>
          <Box className="flex flex-col gap-2">
            <ImageCropper resolution='logo' onCrop={handleCroppedImage} defaultImage={getValues("logo")} />
            {errors.logo && (
              <Typography variant="caption" color="error">
                {errors.logo.message}
              </Typography>
            )}
          </Box>
        </FieldWithLabel>
        <div className="flex justify-end mt-4">
          <Button type="submit" className="!w-fit">
            <Typography textTransform={"capitalize"}>
              Simpan
            </Typography>
          </Button>
        </div>
      </form>
    </>
  )
}
