import { useEffect, useState } from "react";
import { useLoader } from "../../context/loaderContext";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { uploadFile } from "../../api/file";
import { getBanner, setBannerData } from "../../api/news";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUploader from "../FileUploader";

interface FormData {
  banner: string[];
}

const validationSchema = Yup.object({
  banner: Yup.array().of(Yup.string().defined()).required("At least one image is required."),
});

export default function YayasanBannerMultiple() {
  const { showLoader, hideLoader } = useLoader();
  const [initialBanners, setInitialBanners] = useState<any>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const defaultValues = {
    banner: [],
  };

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const res = await getBanner();
        if (res.status) {
          console.log(res.data)
          if (res.data.banner.length) {
            handleFileChange(res.data.banner.map((el: any) => ({
              image: el
            })), "preview")
          }
        }
      } catch (error) {
        console.error("Error fetching program:", error);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, [showLoader, hideLoader, setValue]);

  const handleFileChange = (files: any[], type: string) => {
    if (type === "preview") {
      setInitialBanners(files);
      return;
    }
    setUploadedFiles(files);
  };

  const onSubmit = async (data: FormData) => {
    showLoader();
    try {
      const formData = {
        ...data
      }
      console.log(uploadedFiles)
      console.log(initialBanners)

      if (initialBanners.length > 0) {
        formData.banner = [...initialBanners.map((el: any) => el.image)]
      }

      if (uploadedFiles.length > 0) {
        for await (const galeri of uploadedFiles) {
          try {
            if (galeri.file instanceof File) {
              const fD = new FormData();
              fD.append("file", galeri.file);

              const result = await uploadFile(fD);
              const path = result.data as string;
              formData.banner.push(path);
            }
          } catch (error) {
            console.error("Error uploading file", error);
          }
        }
      }

      const res = await setBannerData({ banner: [...formData.banner] });
      if (res.status) {
        setSnackbar({
          open: true,
          message: "Data berhasil diupdate.",
          severity: "success",
        });
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <div className="w-fit bg-white rounded-md p-4 px-6">
        <p className="font-semibold text-lg">Pengaturan Banner Program</p>
        <p className="font-light text-sm">Gambar akan ditampilkan dalam bentuk carousel banner</p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-y-4 mt-4"
        >
          <FileUploader
            onChange={handleFileChange}
            initialFiles={[...initialBanners]}
            caption={false}
          />
          <div className="flex justify-end mt-4">
            <Button
              className="!w-fit !capitalize"
              type="submit"
            >
              Simpan
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
