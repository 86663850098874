import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base API URL
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("_token_");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.data && !(config.data instanceof FormData)) {
      config.headers["Content-Type"] = "application/json";
      config.data = JSON.stringify(config.data);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    return response.data; // Simplify returning just the data from the response
  },
  async (error) => {
    const originalRequest = error.config;

    // Handle token expiration
    if (error.response?.status === 402 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = Cookies.get("_refreshToken_");
        if (!refreshToken) throw new Error("Refresh token not found");

        const { data } = await axios.post(
          `${process.env.NEXT_PUBLIC_BASE_URL}/auth/refresh`,
          {},
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );

        // Update cookies with new tokens
        Cookies.set("_token_", data.data.access_token, {
          expires: data.data.expires_in / (60 * 60 * 24), // Convert seconds to days
        });
        Cookies.set("_refreshToken_", data.data.refresh.token, {
          expires: data.data.refresh.exp / (60 * 60 * 24), // Convert seconds to days
        });

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${data.data.access_token}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        Cookies.remove("_token_");
        Cookies.remove("_refreshToken_");
        window.location.href = "/"; // Redirect to login or home
        return Promise.reject(refreshError);
      }
    }

    if (error.response?.status === 401) {
      Cookies.remove("_token_");
      Cookies.remove("_refreshToken_");
      window.location.href = "/"; // Redirect to login or home
    }

    console.error("Fetch error:", error);
    return Promise.reject(error);
  }
);

export const fetcher = async (url: string, options: any = {}) => {
  const method = options.method || "GET";
  const params = options.params || null;
  const body = options.body || null;

  try {
    const response = await api({
      url,
      method,
      params,
      data: body,
      headers: options.headers,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
