import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as Yup from "yup"
import { getSocmed, saveSocmed } from '../../api/footer';
import { Alert, Button, Snackbar, TextField, Typography } from '@mui/material';
import FieldWithLabel from '../FieldWithLabel';
import { useLoader } from '../../context/loaderContext';

interface FormSocmed {
  instagram: string;
  facebook: string;
  twitter: string;
  youtube: string;
  tiktok: string;
}

export default function Socmed() {
  const { showLoader, hideLoader } = useLoader();
  const [isEdit, setIsEdit] = useState(false)

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const socmedSchema = Yup.object({
    instagram: Yup.string().required("wajib diisi"),
    facebook: Yup.string().required("wajib diisi"),
    twitter: Yup.string().required("wajib diisi"),
    youtube: Yup.string().required("wajib diisi"),
    tiktok: Yup.string().required("wajib diisi"),
  })

  const {
    register: registerSocmed,
    handleSubmit: handleSubmitSocmed,
    setValue: setValSocmed,
    getValues: getValSocmed,
    formState: { errors: errorSocmed },
    control: controllSocmed,
  } = useForm<FormSocmed>({
    resolver: yupResolver(socmedSchema),
    defaultValues: isEdit ? undefined : {
      instagram: "",
      facebook: "",
      twitter: "",
      youtube: "",
      tiktok: "",
    },
  });

  const onSubmitSocmed = async (data: FormSocmed) => {
    try {
      const payload = { ...data }
      const resSaveSocmed = await saveSocmed(payload)
      if (!resSaveSocmed.status) {
        setSnackbar({
          open: true,
          message: "Data sosial  gagal disimpan",
          severity: "error",
        });
        return
      }

      setSnackbar({
        open: true,
        message: "Data sosial  berhasil disimpan",
        severity: "success",
      });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    showLoader()
    getSocmed()
      .then((res: any) => {
        if (res.status) {
          setValSocmed('instagram', res?.data.instagram)
          setValSocmed('facebook', res?.data.facebook)
          setValSocmed('twitter', res?.data.twitter)
          setValSocmed('youtube', res?.data.youtube)
          setValSocmed('tiktok', res?.data.tiktok)
          setIsEdit(true)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        hideLoader()
      })
  }, [])

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form
        onSubmit={handleSubmitSocmed(onSubmitSocmed)}
        className="w-full flex flex-col gap-y-4 mt-4"
      >
        <FieldWithLabel label="Facebook">
          <TextField
            className="!w-[400px]"
            {...registerSocmed("facebook")}
            error={!!errorSocmed.facebook}
            helperText={errorSocmed.facebook?.message}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Instagram">
          <TextField
            className="!w-[400px]"
            {...registerSocmed("instagram")}
            error={!!errorSocmed.instagram}
            helperText={errorSocmed.instagram?.message}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Twitter.X">
          <TextField
            className="!w-[400px]"
            {...registerSocmed("twitter")}
            error={!!errorSocmed.twitter}
            helperText={errorSocmed.twitter?.message}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Youtube">
          <TextField
            className="!w-[400px]"
            {...registerSocmed("youtube")}
            error={!!errorSocmed.youtube}
            helperText={errorSocmed.youtube?.message}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Tiktok">
          <TextField
            className="!w-[400px]"
            {...registerSocmed("tiktok")}
            error={!!errorSocmed.tiktok}
            helperText={errorSocmed.tiktok?.message}
          />
        </FieldWithLabel>
        <div className="flex justify-end mt-4">
          <Button type="submit" className="!w-fit">
            <Typography textTransform={"capitalize"}>
              Simpan
            </Typography>
          </Button>
        </div>
      </form>
    </>
  )
}
