"use client"

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import FieldWithLabel from '../components/FieldWithLabel';
import QuillEditor from '../components/QuillEditor';
import ImageCropper from '../components/ImageCropper';
import { removeFile, uploadFile } from '../api/file';
import { getHomeData, saveHomeData } from '../api/beranda';

interface FormData {
  hero: string;
  hero_mobile: string;
  logo: string | null;
  hero_tagline: string;
  logo_mobile: string | null;
  tag_line: {
    id: string;
    en: string;
  }
  title: {
    id: string;
    en: string;
  };
  subtitle: {
    id: string;
    en: string | null;
  };
};

const validationSchema = Yup.object({
  hero: Yup.string()
    .nullable(''),
  hero_mobile: Yup.string()
    .nullable(''),
  logo: Yup.string().nullable(),
  logo_mobile: Yup.string().nullable(),
  hero_tagline: Yup.string().nullable(),
  tag_line: Yup.object().shape({
    id: Yup.string().nullable(''),
    en: Yup.string().nullable(''),
  }),
  title: Yup.object().shape({
    id: Yup.string().nullable(''),
    en: Yup.string().nullable(''),
  }),
  subtitle: Yup.object().shape({
    id: Yup.string().nullable(''),
    en: Yup.string().nullable(),
  }),
});

export default function BerandaPage() {
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const [isEdit, setIsEdit] = useState(false)
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const defaultValues = {
    hero: "",
    hero_mobile: "",
    logo: "",
    logo_mobile: "",
    hero_tagline: "",
    tag_line: {
      id: "",
      en: "",
    },
    title: {
      id: "",
      en: "",
    },
    subtitle: {
      id: "",
      en: "",
    },
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm<FormData>({
    //@ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: isEdit ? undefined : defaultValues
  });

  const handleCroppedHero = (image: string) => {
    setValue("hero", image);
  };

  const handleCroppedHeroTagline = (image: string) => {
    setValue("hero_tagline", image);
  };

  const handleCroppedHeroMobile = (image: string) => {
    setValue("hero_mobile", image);
  };

  const handleCroppedLogo = (image: string) => {
    setValue("logo", image);
  };

  const handleCroppedLogoMobile = (image: string) => {
    setValue("logo_mobile", image);
  };


  const onSubmit = async (data: FormData) => {
    let uploadedPath: any = {}
    try {
      const formPayload: any = { ...data }
      const keys: string[] = Object.keys(formPayload)

      for await (const key of keys) {
        if (key.includes("hero") || key.includes("logo")) {
          //@ts-ignore
          if (typeof formPayload[key] === "string" && formPayload[key]?.startsWith("data:")) {
            try {
              const fD = new FormData();
              //@ts-ignore
              // const blob = await fetch(formPayload[key]).then((res) => res.blob());
              fD.append("file", formPayload[key]);
              const result = await uploadFile(fD);
              if (result.status) {
                const path = await result.data as string;
                console.log(path, "><><><><><>><><")
                //@ts-ignore
                uploadedPath[key] = path
              }
            } catch (error) {
              console.log(`Error upload ${key}`)
            }
          }
        }
      }

      Object.keys(uploadedPath).forEach((el: any) => {
        formPayload[el] = uploadedPath[el]
      })

      const resSave = await saveHomeData({
        ...formPayload,
      })
      if (!resSave.status) {
        setSnackbar({
          open: true,
          message: "Data beranda gagal disimpan",
          severity: "error",
        });
        return
      }

      setSnackbar({
        open: true,
        message: "Data beranda berhasil disimpan",
        severity: "success",
      });

    } catch (error) {
      console.log(error)
      const paths = Object.keys(uploadedPath)
      paths.forEach(async (k: string) => {
        //@ts-ignore
        if (uploadedPath[k]?.startsWith("http")) {
          //@ts-ignore
          await removeFile(uploadedPath[k])
        }
      })
    }
  }

  useEffect(() => {
    getHomeData().then((res: any) => {
      if (res.status) {
        console.log(res.data)
        setValue("hero", res.data?.hero)
        setValue("hero_mobile", res.data?.hero_mobile)
        setValue("logo", res.data?.logo)
        setValue("logo_mobile", res.data?.logo_mobile)
        setValue("subtitle.id", res.data?.subtitle?.id)
        setValue("subtitle.en", res.data?.subtitle?.en)
        setValue("title.id", res.data?.title?.id)
        setValue("title.en", res.data?.title?.en)
        setValue("hero_tagline", res.data?.hero_tagline)
        setValue("tag_line.id", res.data?.tag_line?.id)
        setValue("tag_line.en", res.data?.tag_line?.en)

        setIsEdit(true)
      }
    }).catch((error) => {
      console.log(error)
    })
  }, [])


  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div className="mb-8">
        <h1 className="font-bold text-3xl mb-2">Mengatur Halaman Beranda</h1>
        <p className="text-sm text-neutral-600">
          Pengaturan Banner dan Title di halaman Beranda
        </p>
      </div>
      <Box className="bg-white pt-2 pb-6 px-6 rounded items-center shadow-md gap-y-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-y-4 mt-4"
        >
          <FieldWithLabel label="Banner Desktop" applyMargin={true}>
            <Box className="flex flex-col gap-2">
              <ImageCropper resolution='banner' size='big' onCrop={handleCroppedHero} defaultImage={getValues("hero")} />
              {errors.hero && (
                <Typography variant="caption" color="error">
                  {errors.hero.message}
                </Typography>
              )}
            </Box>
          </FieldWithLabel>
          <FieldWithLabel label="Banner Mobile" applyMargin={true}>
            <Box className="flex flex-col gap-2">
              <ImageCropper resolution='mobile' className={"w-1/3"} onCrop={handleCroppedHeroMobile} defaultImage={getValues("hero_mobile")} />
              {errors.hero_mobile && (
                <Typography variant="caption" color="error">
                  {errors.hero_mobile.message}
                </Typography>
              )}
            </Box>
          </FieldWithLabel>
          <div className='w-1/2 flex gap-4'>
            <FieldWithLabel label="Logo Desktop" applyMargin={true}>
              <Box className="flex flex-col gap-2">
                <ImageCropper resolution='logo' size="small" onCrop={handleCroppedLogo} defaultImage={getValues("logo")} />
                {errors.logo && (
                  <Typography variant="caption" color="error">
                    {errors.logo.message}
                  </Typography>
                )}
              </Box>
            </FieldWithLabel>
            <FieldWithLabel label="Logo Mobile" applyMargin={true}>
              <Box className="flex flex-col gap-2">
                <ImageCropper resolution='logo' size="small" onCrop={handleCroppedLogoMobile} defaultImage={getValues("logo_mobile")} />
                {errors.logo_mobile && (
                  <Typography variant="caption" color="error">
                    {errors.logo_mobile.message}
                  </Typography>
                )}
              </Box>
            </FieldWithLabel>
          </div>
          <FieldWithLabel label="Judul (Bahasa Indonesia)" required>
            <TextField
              {...register("title.id")}
              error={!!errors?.title?.id}
              helperText={errors?.title?.id?.message}
            />
          </FieldWithLabel>
          <FieldWithLabel label="Subtitle (Bahasa Indonesia)" required applyMargin={true}>
            <Box>
              <QuillEditor name="subtitle.id" control={control} />
            </Box>
          </FieldWithLabel>
          <FieldWithLabel label="Judul (Bahasa Inggris)" required>
            <TextField
              {...register("title.en")}
              error={!!errors?.title?.en}
              helperText={errors?.title?.en?.message}
            />
          </FieldWithLabel>
          <FieldWithLabel label="Subtitle (Bahasa Inggris)" required applyMargin={true}>
            <Box>
              <QuillEditor name="subtitle.en" control={control} />
            </Box>
          </FieldWithLabel>
          <FieldWithLabel label="Banner Tagline" applyMargin={true}>
            <Box className="flex flex-col gap-2">
              <ImageCropper resolution='banner' onCrop={handleCroppedHeroTagline} defaultImage={getValues("hero_tagline")} />
              {errors.hero_tagline && (
                <Typography variant="caption" color="error">
                  {errors.hero_tagline.message}
                </Typography>
              )}
            </Box>
          </FieldWithLabel>
          <div className='grid grid-cols-2 gap-4'>
            <FieldWithLabel label="Tagline (Bahasa Indonesia)" required applyMargin={true}>
              <Box>
                <QuillEditor name="tag_line.id" control={control} />
              </Box>
            </FieldWithLabel>
            <FieldWithLabel label="Tagline (Bahasa Inggris)" required applyMargin={true}>
              <Box>
                <QuillEditor name="tag_line.en" control={control} />
              </Box>
            </FieldWithLabel>
          </div>
          <div className="flex justify-end mt-4">
            <Button type="submit" className="!w-fit">
              <Typography textTransform={"capitalize"}>
                Simpan
              </Typography>
            </Button>
          </div>
        </form>
      </Box>
    </>
  )
}
