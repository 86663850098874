import FieldWithLabel from "../FieldWithLabel";
import { useLoader } from "../../context/loaderContext";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import QuillEditor from "../QuillEditor";
import { useForm } from "react-hook-form";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { setProgram, getProgram } from "../../api/program";
import { useEffect, useState } from "react";
type FormData = {
  history_id: string;
  vision_id: string;
  mission_id: string;
  history_en: string;
  vision_en: string;
  mission_en: string;
};

const validationSchema = Yup.object({
  history_id: Yup.string().defined(),
  vision_id: Yup.string().defined(),
  mission_id: Yup.string().defined(),
  history_en: Yup.string().defined(),
  vision_en: Yup.string().defined(),
  mission_en: Yup.string().defined(),
});

export default function YayasanVision() {
  const { showLoader, hideLoader } = useLoader();

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const defaultValues = {
    history_id: "",
    vision_id: "",
    mission_id: "",
    history_en: "",
    vision_en: "",
    mission_en: "",
  };

  const formMethods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const {
    setValue,
    // getValues,
    handleSubmit,
    formState: { errors },
    control,
  } = formMethods;

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const res = await getProgram();
        if (res.status) {
          setValue("history_id", res.data.history.id || "");
          setValue("history_en", res.data.history.en || "");
          setValue("mission_id", res.data.mission.id || "");
          setValue("mission_en", res.data.mission.en || "");
          setValue("vision_id", res.data.vision.id || "");
          setValue("vision_en", res.data.vision.en || "");
        }
      } catch (error) {
        console.error("Error fetching program:", error);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, [showLoader, hideLoader, setValue]);

  const onSubmit = async (data: FormData) => {
    showLoader();
    try {
      const submitData = {
        history: {
          id: data.history_id,
          en: data.history_en,
        },
        vision: {
          id: data.vision_id,
          en: data.vision_en,
        },
        mission: {
          id: data.mission_id,
          en: data.mission_en,
        },
      };

      console.log(submitData, "+_+_+_+")

      const res = await setProgram(submitData);
      if (res.status) {
        setSnackbar({
          open: true,
          message: "Data berhasil diupdate.",
          severity: "success",
        });
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-y-4 mt-4"
      >
        <div className="grid grid-cols-2 gap-6">
          <div className="w-fit bg-white rounded-md p-4 px-6">
            <FieldWithLabel
              label="Sejarah (Bahasa Indonesia)"
              required
              applyMargin={true}
            >
              <Box className="flex flex-col gap-2 mb-6">
                <QuillEditor name="history_id" control={control} />
                {errors.history_id && (
                  <Typography variant="caption" color="error">
                    {errors.history_id.message}
                  </Typography>
                )}
              </Box>
            </FieldWithLabel>

            <FieldWithLabel
              label="Visi (Bahasa Indonesia)"
              required
              applyMargin={true}
            >
              <Box className="flex flex-col gap-2 mb-6">
                <QuillEditor name="vision_id" control={control} />
                {errors.vision_id && (
                  <Typography variant="caption" color="error">
                    {errors.vision_id.message}
                  </Typography>
                )}
              </Box>
            </FieldWithLabel>

            <FieldWithLabel
              label="Misi (Bahasa Indonesia)"
              required
              applyMargin={true}
            >
              <Box className="flex flex-col gap-2">
                <QuillEditor name="mission_id" control={control} />
                {errors.mission_id && (
                  <Typography variant="caption" color="error">
                    {errors.mission_id.message}
                  </Typography>
                )}
              </Box>
            </FieldWithLabel>
          </div>
          <div className="w-fit bg-white rounded-md p-4 px-6">
            <FieldWithLabel
              label="Sejarah (Bahasa Inggris)"
              required
              applyMargin={true}
            >
              <Box className="flex flex-col gap-2 mb-6">
                <QuillEditor name="history_en" control={control} />
                {errors.history_en && (
                  <Typography variant="caption" color="error">
                    {errors.history_en.message}
                  </Typography>
                )}
              </Box>
            </FieldWithLabel>

            <FieldWithLabel
              label="Visi (Bahasa Inggris)"
              required
              applyMargin={true}
            >
              <Box className="flex flex-col gap-2 mb-6">
                <QuillEditor name="vision_en" control={control} />
                {errors.vision_en && (
                  <Typography variant="caption" color="error">
                    {errors.vision_en.message}
                  </Typography>
                )}
              </Box>
            </FieldWithLabel>

            <FieldWithLabel
              label="Misi (Bahasa Inggris)"
              required
              applyMargin={true}
            >
              <Box className="flex flex-col gap-2">
                <QuillEditor name="mission_en" control={control} />
                {errors.mission_en && (
                  <Typography variant="caption" color="error">
                    {errors.mission_en.message}
                  </Typography>
                )}
              </Box>
            </FieldWithLabel>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button type="submit" className="!w-fit !capitalize">
            Simpan
          </Button>
        </div>
      </form>
    </>
  );
}
